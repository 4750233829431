import React, { ReactNode } from 'react';
import { Spinner, Col, Row } from 'react-bootstrap';
import '../Styles/SpinnerComp.css';

interface SpinnerCompProps {
    displayText?: string;
}

const SpinnerComp: React.FC<SpinnerCompProps> = ({ displayText }) => {
    const renderDisplayText = (): ReactNode => (
        <Row id="loading-text" hidden={!displayText}>
            <Row><p className="spinner-text">Loading content... Thank you for waiting.</p></Row>
            <Col xs="auto"><p className="spinner-text">{displayText}</p></Col>
            <Col xs={1} className="mt-2"><Spinner animation="grow" size='sm' /></Col>
            <Col xs={1} className="mt-2"><Spinner animation="grow" size='sm' /></Col>
            <Col xs={1} className="mt-2"><Spinner animation="grow" size='sm' /></Col>
        </Row>
    );

    const content = displayText
        ? renderDisplayText()
        : <Spinner animation="border" className="spinner-custom" />;

    return (
        <div id="loading-content">
            <Row className="spinner-container">
                {content}
            </Row>
        </div>
    );
};
export default SpinnerComp;