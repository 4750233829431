import React, { Component } from 'react';
import { Col, Nav, Row, Tabs, Tab } from 'react-bootstrap';
import { BaseUrl } from '../baseRoute';
import BarChartWrapper from './ReportViews/BarChartWrapper';
import SpinnerComp from './Common/SpinnerComp';
import ValueDisplayComp from './Common/ValueDisplayComp';
import DoughnutChartWrapper from './ReportViews/DoughnutChartWrapper';
import './Styles/ValueDisplayComp.css';

export class Church extends Component {
    constructor(props) {
        super(props);
        this.state = {
            churchName: 'N/A',
            regionName: 'N/A',
            location: '',
            loadingHeader: true,
            loadingServices: true,
            loadingBargraph: true,
            loadingAgeBreakdownChart: true,
            loadingServiceTypeBreakdownChart: true,
            services: [],
            graphData: [],
            toDate: "",
            fromDate: "",
            serviceTypeChartData: {
                labels: [],
                data: [],
                colors: [],
            },
            ageBreakdownData: {
                labels: [],
                data: [],
                colors: [],
            }
        };
    }

    componentDidMount() {
        this.refreshServicesTable();
        this.fetchPageHeader();
        this.fetchBarGraphData(this.state.toDate, this.state.fromDate);
        this.fetchServiceTypeByDate(this.state.toDate, this.state.fromDate);
        this.fetchAgeBreakdownChart(this.state.toDate, this.state.fromDate);
    }

    updateToDateGrandparent(value) {
        this.setState({ toDate: value });
        this.fetchBarGraphData(value, this.state.fromDate);
        this.fetchServiceTypeByDate(value, this.state.fromDate);
        this.fetchAgeBreakdownChart(value, this.state.fromDate);
    }

    updateFromDateGrandparent(value) {
        this.setState({ fromDate: value });
        this.fetchBarGraphData(this.state.toDate, value);
        this.fetchServiceTypeByDate(this.state.toDate, value);
        this.fetchAgeBreakdownChart(this.state.toDate, value);
    }

    renderPageHeader(churchName, pastorName, regionName, location) {
        if (churchName == null || regionName == null) {
            return (<div><h1 className="text-center mt-5">Failed to retrieve church information.</h1>
                <h3 className="text-center mb-5">Contact Peter with church name and Region to resolve issue.</h3></div>)
        }
        return (
            <div className='fancy-text px-3'>
                <h1 className="text-center"><span className="fancy-text-stronger">{churchName}</span></h1>
                <h3 className="text-center">of the <span className="fancy-text-stronger">{regionName}</span> region</h3>
                <h5>Served by Pastor: <span className="fancy-text-strong">{pastorName}</span></h5>
                <h5>Location: <span className="fancy-text-strong">{location}</span></h5>
            </div>
        );
    }

    renderGraphs() {
        if (this.state.services < 1) {
            return (
                <h3 className="text-center mt-5">No services have been entered for the following church.</h3>
            )
        }
        let barGraph = this.state.loadingBargraph
            ? <SpinnerComp displayText='Loading Graph data' />
            : <BarChartWrapper chartTitle="Monthly Attendance" graphData={this.state.graphData} updateToDateGrandparent={this.updateToDateGrandparent.bind(this)} updateFromDateGrandparent={this.updateFromDateGrandparent.bind(this)} toDate={this.state.toDate} fromDate={this.state.fromDate} />

        let serviceTypeChart = this.state.loadingServiceTypeBreakdownChart
            ? <SpinnerComp displayText='Loading Chart' />
            : <DoughnutChartWrapper data={this.state.serviceTypeChartData} chartTitle="Attendance by Service Type" updateToDateGrandparent={this.updateToDateGrandparent.bind(this)} updateFromDateGrandparent={this.updateFromDateGrandparent.bind(this)} toDate={this.state.toDate} fromDate={this.state.fromDate} />

        let ageBreakdownChart = this.state.loadingAgeBreakdownChart
            ? <SpinnerComp displayText='Loading Chart' />
            : <DoughnutChartWrapper data={this.state.ageBreakdownData} chartTitle="Attendance by Age Group" updateToDateGrandparent={this.updateToDateGrandparent.bind(this)} updateFromDateGrandparent={this.updateFromDateGrandparent.bind(this)} toDate={this.state.toDate} fromDate={this.state.fromDate} />
        return (
            <Col lg={9}>
                <h6 className="text-center mt-4">Select tabs to view different graphs</h6>
                <Tabs defaultActiveKey="barGraphTab" id="content-tabs" fill>
                    <Tab eventKey="barGraphTab" title="Monthly Attendance" tabClassName="color-tabs-2">
                        <div className="custom-color-green-fade pt-2">
                            {barGraph}
                        </div>                        
                    </Tab>
                    <Tab eventKey="doughnutChartTab" title="Service Type" tabClassName="color-tabs-2">
                        <div className="custom-color-green-light pt-2">
                            {serviceTypeChart}
                        </div>
                    </Tab>
                    <Tab eventKey="ageChartTab" title="Age Breakdown" tabClassName="color-tabs-2">
                        <div className="custom-color-green-dark pt-2">
                            {ageBreakdownChart}
                        </div>
                    </Tab>
                </Tabs>
            </Col>
        )
    }

    renderServices() {
        if (this.state.services.length < 1) {
            return (
                <>
                    <h3 className="text-center mt-4">Enter services to view church data.</h3>
                    <h5><Nav.Link className="text-center" href='/'>Return to Home</Nav.Link></h5>
                </>
            )
        }
        const valueDisplayData = this.state.services.map(obj => (
            {
                name: obj.serviceDate,
                link: '',
                children: [
                    {
                        label: 'Type', value: obj.serviceType
                    },
                    {
                        label: 'Attendance', value:obj.totalAttendance
                    }, 
                    {
                        label: 'Men', value: obj.menAttendance
                    },
                    {
                        label: 'Women', value: obj.womenAttendance
                    },
                    {
                        label: 'Youth Female', value: obj.youthFemaleAttendance
                    },
                    {
                        label: 'Youth Male', value: obj.youthMaleAttendance
                    },
                    {
                        label: 'Teen Female', value: obj.teenFemaleAttendance
                    },
                    {
                        label: 'Teen Male', value: obj.teenMaleAttendance
                    },
                    {
                        label: 'Children Male', value: obj.childrenMaleAttendance
                    },
                    {
                        label: 'Children Female', value: obj.childrenFemaleAttendance
                    },
                    {
                        label: 'Born Again', value: obj.bornAgain
                    },
                    {
                        label: 'Offering Collected', value: obj.offeringCollected
                    },

                ]
            }));
        return (
            <Col xl={3}>
                <div className="p-2 mt-3">
                    <ValueDisplayComp items={valueDisplayData} title="Recent Services" />
                </div>
            </Col>
        );
    }

    async refreshServicesTable() {
        const id = this.props.match.params.churchId;
        fetch(`${BaseUrl.Path}api/services/church/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({ services: data, loadingServices: false })
            })
            .catch(error => { console.log("The error is:", error) })
    }

    async fetchPageHeader() {
        const id = this.props.match.params.churchId;
        fetch(`${BaseUrl.Path}api/churches/church-profile/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({ pastor: data.pastor.pastorName, churchName: data.name, regionName: data.regionName, location: data.location, loadingHeader: false })
            })
            .catch(error => { console.log("Unable to retrieve church profile info. The error is:", error) })
    }

    async fetchBarGraphData(toDate,fromDate) {
        const id = this.props.match.params.churchId;
        let today = new Date();
        if (toDate === "") {
            toDate = today.getFullYear() + '-01-01';
        }
        if (fromDate === "") {
            let month = (today.getMonth() + 1).toString().padStart(2, '0');
            let day = today.getDate().toString().padStart(2, '0');
            fromDate = today.getFullYear() + '-' + month + '-' + day;
        }

        const url = new URL(`${BaseUrl.Path}api/report/custom-church-attendance/${id}`);
        url.searchParams.append('startDate', toDate);
        url.searchParams.append('endDate', fromDate);

        fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=utf-8' }
        }).then((response) => response.json()).then((data) => {
            this.setState({ graphData: data, loadingBargraph: false, toDate: toDate, fromDate: fromDate })
        }).catch(error => { console.log("The error is:", error) });
    };

    async fetchServiceTypeByDate(toDate,fromDate) {
        const id = this.props.match.params.churchId;
        let today = new Date();
        if (toDate === "") {
            toDate = today.getFullYear() + '-01-01';
        }
        if (fromDate === "") {
            let month = (today.getMonth() + 1).toString().padStart(2, '0');
            let day = today.getDate().toString().padStart(2, '0');
            fromDate = today.getFullYear() + '-' + month + '-' + day;
        }

        const url = new URL(`${BaseUrl.Path}api/report/service-type-breakdown/church/${id}`);
        url.searchParams.append('startDate', toDate);
        url.searchParams.append('endDate', fromDate);

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                this.setState({ serviceTypeChartData: data, toDate: toDate, fromDate: fromDate, loadingServiceTypeBreakdownChart: false })
            })
            .catch(error => { console.log("The error is:", error) })
    }

    async fetchAgeBreakdownChart(toDate, fromDate) {
        const id = this.props.match.params.churchId;
        let today = new Date();
        if (toDate === "") {
            toDate = today.getFullYear() + '-01-01';
        }
        if (fromDate === "") {
            let month = (today.getMonth() + 1).toString().padStart(2, '0');
            let day = today.getDate().toString().padStart(2, '0');
            fromDate = today.getFullYear() + '-' + month + '-' + day;
        }

        const url = new URL(`${BaseUrl.Path}api/report/age-breakdown/${id}`);
        url.searchParams.append('startDate', toDate);
        url.searchParams.append('endDate', fromDate);

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                this.setState({ ageBreakdownData: data, loadingAgeBreakdownChart: false, toDate: toDate, fromDate: fromDate })
            })
            .catch(error => { console.log("The error is:", error) })
    }

    render() {
        let pageHeader = this.state.loadingHeader
            ? <SpinnerComp displayText='Loading...' />
            : this.renderPageHeader(this.state.churchName, this.state.pastor, this.state.regionName, this.state.location);

        let services = this.state.loadingServices
            ? <SpinnerComp/>
            : this.renderServices();
        const graphs = this.renderGraphs();

        return (            
            <div>
                <Row>
                    {pageHeader}
                    {graphs}
                    {services}
                </Row>
            </div>
        );
    }
}