import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Container, Row } from 'react-bootstrap';
import DateTimePicker from '../Common/DateTimePicker';
import DateTimePickerError from '../Common/DateTimePickerError';
ChartJS.register(ArcElement, Tooltip, Legend);

type MyProps = {
    data: {
        labels: string[];
        data: number[];
        colors: string[];
    };
    chartTitle: string;
    toDate?: string;
    fromDate?: string;
    updateToDateGrandparent?: (value: string) => void;
    updateFromDateGrandparent?: (value: string) => void;
    showDateTimePicker?: boolean;
};

const DoughnutChartWrapper: React.FC<MyProps> = ({ data, chartTitle, toDate, fromDate, updateToDateGrandparent, updateFromDateGrandparent, showDateTimePicker }) => {

    const updateToDateParent = (value: string) => {
        updateToDateGrandparent && updateToDateGrandparent(value);
    };

    const updateFromDateParent = (value: string) => {
        updateFromDateGrandparent && updateFromDateGrandparent(value);
    };

    const hasGraphData = data.data && data.data.length > 0;

    const chartData = {
        labels: data.labels,
        datasets: [{
            data: data.data,
            backgroundColor: data.colors
        }]
    };

    /*Do not show DateTimePicker if showDateTimePicker is null or false*/
    const dateTimePicker = (showDateTimePicker === false) ? (null) : (
        <Row className="custom-align-right">
            <DateTimePicker
                updateToDateParent={updateToDateParent}
                updateFromDateParent={updateFromDateParent}
                toDate={toDate || ''}
                fromDate={fromDate || ''}
            />
        </Row>);

    /*Do not show DateTimePickerError if toDate or fromDate is null*/
    const errorMessage = (showDateTimePicker === false) ?
        (<h5 className="text-center mt-5 mb-5">Something went wrong..No data for doughnut chart</h5 >
        ) : (<DateTimePickerError toDate={toDate || ''} fromDate={fromDate || ''} />);

    return (
        <Container className="pb-5">
            {dateTimePicker}
            <Row>
                {hasGraphData ? (
                    <div className="bg-color-white">
                        <h2 className="fancy-text text-center">{chartTitle}</h2>
                        <Doughnut data={chartData} className="pie-chart-container" />
                    </div>
                ) : (<Row>{errorMessage}</Row>)}
            </Row>
        </Container>
    );
};

export default DoughnutChartWrapper;
