import React, { ChangeEvent, useEffect, useState, useRef } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { BaseUrl } from '../../baseRoute';
import '../Styles/ModalStyles.css';

interface Region {
    regionId: string;
    regionName: string;
}

interface Props {
    userId: string;
    userName: string;
    email: string;
    showModal: boolean;
    updateAssignRegionParent: (value: boolean) => void;
}

const AssignRegion: React.FC<Props> = (props) => {
    const [validated, setValidated] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const [regionList, setRegionList] = useState<Region[]>([]);
    const [regionId, setRegionId] = useState('');

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        if (name === 'regionId') {
            setRegionId(value);
        }
    };

    const handleSubmit = () => {
        setIsDisabled(true);
        setValidated(true);
        if (formRef.current && formRef.current.checkValidity() === false) {
            setIsDisabled(false);
            return;
        }
        fetch(`${BaseUrl.Path}api/users/Assign-Region`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                userId: props.userId,
                regionId: regionId
            })
        }).then((response) => {
            if (response.ok) {
                props.updateAssignRegionParent(false);
            }
        }).catch((error) => {
            setIsDisabled(false);
            console.log("The error is:", error);
        });
    };

    const handleClose = () => {
        props.updateAssignRegionParent(false);
    };

    useEffect(() => {
        fetchRegions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function fetchRegions() {
        const response = await fetch(`${BaseUrl.Path}api/regions/unassigned`);
        const data = await response.json();
        setRegionList(data);
    };

    return (
        <div>
            <Modal show={props.showModal} className="bg-dark fancy-text-strong text-center">
                <Modal.Body>
                    <h2 className="mb-3 border-bottom border-dark"><strong>Assign Region</strong></h2>
                    <h5 className="mb-4 modal-form-label-text text-center">Select a region from the dropdown you wish to assign to <strong>{props.email}</strong>.</h5>
                    <h5 className="mb-4 modal-form-label-text text-center">Proceeding will make <strong> {props.userName}</strong> a regional overseer for selected region.</h5>
                    <Form id="createForm" ref={formRef as React.RefObject<HTMLFormElement>} validated={validated}>
                        <Form.Group controlId="formRegion" className="mb-4">
                            <Form.Control as="select"
                                required
                                value={regionId}
                                onChange={handleChange}
                                name="regionId">
                                isInvalid={regionId === ''}
                                <option key={0} value={''}>Choose Region...</option>
                                {regionList.map(c =>
                                    <option key={c.regionId} value={c.regionId}>{c.regionName}</option>
                                )}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid" className="modal-form-invalid-text">Select a region to assign to user</Form.Control.Feedback>
                        </Form.Group>
                        <Button disabled={isDisabled} className="modal-submit-btn" onClick={handleSubmit}>
                            Yes, Assign Region
                        </Button>
                        <Button className="modal-close-btn" onClick={handleClose}>
                            Close
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AssignRegion;