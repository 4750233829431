import React, { ChangeEvent, useState, useRef, useEffect } from 'react';
import { Button, Form, Modal, Row, Col } from 'react-bootstrap';
import { BaseUrl } from '../../baseRoute';
import '../Styles/ModalStyles.css';

interface Region {
    regionId: string;
    regionName: string;
}

interface CreateChurchProps {
    showModal: boolean;
    updateCreateModalParent: (value: boolean) => void;
}

const CreateChurch: React.FC<CreateChurchProps> = (props) => {
    const [regionsList, setRegionList] = useState<Region[]>([]);
    const [validated, setValidated] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const [churchName, setChurchName] = useState('');
    const [estimateCommunityPopulation, setEstimateCommunityPopulation] = useState(0);
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [regionId, setRegionId] = useState('');

    useEffect(() => {
        getRegionDowndownList();
    }, []);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        switch (name) {
            case 'churchName': {
                setChurchName(value);
                break;
            }
            case 'estimateCommunityPopulation': {
                setEstimateCommunityPopulation(parseInt(value));
                break;
            }
            case 'address': {
                setAddress(value);
                break;
            }
            case 'city': {
                setCity(value);
                break;
            }
            case 'country': {
                setCountry(value);
                break;
            }
            case 'regionId': {
                setRegionId(value);
                break;
            }
            default: {
                break;
            }
        }

    };

    const handleClose = () => {
        props.updateCreateModalParent(false);
    };

    const handleSubmit = () => {
        setIsDisabled(true);
        setValidated(true);
        if (formRef.current && formRef.current.checkValidity() === false) {
            setIsDisabled(false);
            return;
        }
        fetch(`${BaseUrl.Path}api/churches/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                name: churchName,
                estimateCommunityPopulation: estimateCommunityPopulation,
                address: address,
                city: city,
                country: country,
                regionId: regionId
            })
        }).then((response) => {
            if (response.ok) {
                props.updateCreateModalParent(false);
            }
        }).catch((error) => {
            setIsDisabled(false);
            console.log("The error is:", error);
        });
    };

    const getRegionDowndownList = async () => {
        fetch(`${BaseUrl.Path}api/regions/`, { method: 'GET' })
            .then(response => response.json())
            .then(data => {
                setRegionList(data);                    
            }).catch(error => console.log("Error returning regions... ", error));        
    };

    return (
        <Modal show={props.showModal}>
            <Modal.Body>
                <h4 className="modal-header-text mb-3">Create Church</h4>
                <Form id="createForm" ref={formRef as React.RefObject<HTMLFormElement>} validated={validated}>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formName" className="modal-form-label-text">
                            <Form.Label>Church Name</Form.Label>
                            <Form.Control required type="text" placeholder="Enter Church Name" name="churchName" value={churchName} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid" className="modal-form-invalid-text"> Enter a church name</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formEstimateCommunityPopulation" className="modal-form-label-text">
                            <Form.Label>Estimated Population</Form.Label>
                            <Form.Control required type="number" name="estimateCommunityPopulation" value={estimateCommunityPopulation} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid" className="modal-form-invalid-text"> Enter population</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Form.Group controlId="formAddress" className="modal-form-label-text mb-3">
                        <Form.Label>Address</Form.Label>
                        <Form.Control type="text" placeholder="1234 Main St" name="address" value={address} onChange={handleChange} />
                    </Form.Group>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formCity" className="modal-form-label-text">
                            <Form.Label>City / County</Form.Label>
                            <Form.Control type="text" placeholder="City" name="city" value={city} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formCountry" className="modal-form-label-text">
                            <Form.Label>Country</Form.Label>
                            <Form.Control type="text" placeholder="Country" name="country" value={country} onChange={handleChange} />
                        </Form.Group>
                    </Row>
                    <Form.Group controlId="formRegion" className="modal-form-label-text mb-3">
                        <Form.Label>Regions</Form.Label>
                        <Form.Control as="select"
                            required
                            value={regionId}
                            onChange={handleChange}
                            name="regionId">
                            isInvalid={regionId === ''}
                            <option key={0} value={''}>Choose Region...</option>
                            {regionsList.map(r =>
                                <option key={r.regionId} value={r.regionId}>{r.regionName}</option>
                            )}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" className="modal-form-invalid-text">Select a Region</Form.Control.Feedback>
                    </Form.Group>
                    <Button disabled={isDisabled} className="modal-submit-btn" onClick={handleSubmit}>
                        Create
                    </Button>
                    <Button className="modal-close-btn" onClick={handleClose}>
                        Close
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateChurch;
