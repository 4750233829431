import React, { useState, useEffect } from 'react';
import { Alert,Col } from 'react-bootstrap';
import { BaseUrl } from '../../baseRoute';
import '../Styles/Announcements.css';

interface Announcement {
    announcementId: string;
    text: string;
    expirationDateTime: string;
}

const Announcements = () => {
    const [data, setData] = useState<Announcement[]>([]);
    const [show, setShow] = useState(true);

    useEffect(() => {
        fetchAnnouncements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchAnnouncements = () => {
        fetch(`${BaseUrl.Path}api/announcements/get-announcements`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=utf-8' }
        })
            .then((response) => response.json())
            .then((data: Announcement[]) => {
                setData(data);
            })
            .catch((error) => {
                console.log("The error is:", error);
            });
    };

    const renderAnnouncement = (data: Announcement[]) => {
        return (
            <Alert className="alert-main" onClose={() => setShow(false)} dismissible>
                <Alert.Heading className="alert-header"><strong>Announcements!</strong></Alert.Heading>
                {data.map((announcement) => (
                    <h5 className="alert-body" key={announcement.announcementId}>{announcement.text}</h5>
                    ))}
            </Alert>
        );
    };

    let content = (show && data.length > 0) ? renderAnnouncement(data) : null;
    return <Col>{content}</Col>;

};

export default Announcements;
