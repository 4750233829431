import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import '../Styles/GraphStyles.css';

interface DateTimePickerProps {
    toDate: string;
    fromDate: string;
    updateToDateParent: (date: string) => void;
    updateFromDateParent: (date: string) => void;
    backgroundColor?: string;
    labelColor?: string;
}

const DateTimePicker: React.FC<DateTimePickerProps> = ({
    toDate,
    fromDate,
    updateToDateParent,
    updateFromDateParent,
    backgroundColor = 'custom-color-blue-gray',
    labelColor = 'text-white'
}) => {

    const handleToDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateToDateParent(event.target.value);
    };

    const handleFromDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateFromDateParent(event.target.value);
    };

    return (
        <div className={`pb-2 pt-1 rounded date-filter ${backgroundColor} ${labelColor}`}>
            <Row>
                <Col>
                    <Form.Label>Start Date:</Form.Label>
                    <Form.Control
                        type="date"
                        name="toDate"
                        value={toDate}
                        onChange={handleToDateChange}
                    />
                </Col>
                <Col>
                    <Form.Label>End Date:</Form.Label>
                    <Form.Control
                        type="date"
                        name="fromDate"
                        value={fromDate}
                        onChange={handleFromDateChange}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default DateTimePicker;