import React from 'react';
import { Table, Container, Row, Col } from 'react-bootstrap';
import '../Styles/RegionOverviewChart.css';

interface ChildProps {
    childName: string;
    properties: {
        key: string;
        value: string;
    }[];
}

interface ItemProps {
    id: string;
    name: string;
    subTitle: string;
    children: ChildProps[];
}

interface MyProps {
    items: ItemProps[];
}

const RegionOverviewChart: React.FC<MyProps> = ({ items }) => {
    if (items.length === 0) {
        return <h2 className="text-center my-5">Search results returned no records...</h2>;
    }

    return (
        <Container className="overview-chart-div rounded">
            <p className="overview-chart-title">Overview Chart</p>
            <Row>
                {items.map((item, index) => (
                    <Col key={index} className="overview-chart-parent p-2">
                        <h3 className="mx-3">Region: <strong>{item.name}</strong></h3>
                        <h5 className="mx-3">Overseer: <strong>{item.subTitle}</strong></h5>
                        <Table responsive="sm">
                            <thead className="overview-chart-table-header">
                                <tr>
                                    <th>#</th>
                                    <th>Church</th>
                                    <th>Pastor</th>
                                    <th>Location</th>
                                    <th>Population</th>
                                </tr>
                            </thead>
                            <tbody>
                                {item.children.map((child, i) => (
                                    <tr key={i} className="overview-chart-child">
                                        <td>{i + 1}</td>
                                        <td>{child.childName}</td>
                                        {Object.entries(child.properties).map(([key, value]) =>
                                            <td key={key + value}>{value}</td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};
export default RegionOverviewChart;