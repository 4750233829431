import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { BaseUrl } from '../../baseRoute';
import '../Styles/ModalStyles.css';

interface Props {
    userId: string;
    churchId: string;
    userName: string;
    email: string;
    showModal: boolean;
    updateUnassignChurchParent: (value: boolean) => void;
}

const UnassignChurch: React.FC<Props> = (props) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [churchName, setChurchName] = useState('');

    const handleSubmit = () => {
        setIsDisabled(true);
        fetch(`${BaseUrl.Path}api/users/Unassign-Church`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                userId: props.userId,
                churchId: props.churchId
            })
        }).then((response) => {
            if (response.ok) {
                props.updateUnassignChurchParent(false);
            }
        }).catch((error) => {
            setIsDisabled(false);
            console.log("The error is:", error);
        });
    };

    const handleClose = () => {
        props.updateUnassignChurchParent(false);
    };

    useEffect(() => {
        fetchChurchById();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function fetchChurchById() {
        const id = props.churchId
        if (!id) {
            setChurchName("N/A");
            setIsDisabled(true);
            return;
        }
        fetch(`${BaseUrl.Path}api/churches/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then((response) => response.json())
            .then((data) => {
                setChurchName(data.name);
            }).catch(error => { console.log("Something we wrong! Error:", error) });
    };

    return (
        <div>
            <Modal show={props.showModal} className="bg-dark fancy-text-strong text-center">
                <Modal.Body>
                    <h2 className="mb-3 border-bottom border-dark"><strong>Unassign Church</strong></h2>
                    <h4 className="mb-4 modal-form-label-text text-center">Are you sure you want to remove <strong>{churchName}</strong> from <strong>{props.email}</strong>.</h4>
                    <h5 className="mb-4 modal-form-label-text text-center">Proceeding will remove <strong> {props.userName}</strong> as the pastor for <strong>{churchName}</strong>.</h5>
                    <Button disabled={isDisabled} className="modal-submit-btn" onClick={handleSubmit}>
                        Yes, Unassign Church
                    </Button>
                    <Button className="modal-close-btn" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default UnassignChurch;