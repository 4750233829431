import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { BaseUrl } from '../../baseRoute';

interface Props {
    announcementId: string;
    showModal: boolean;
    updateDeleteModalParent: (value: boolean) => void;
}

const DeleteAnnouncement: React.FC<Props> = (props) => {
    const [isDisabled, setIsDisabled] = useState(false);

    const handleClose = () => {
        props.updateDeleteModalParent(false);
    };

    const handleSubmit = () => {
        setIsDisabled(true);
        fetch(`${BaseUrl.Path}api/announcements/delete/${props.announcementId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        })
            .then((response) => {
                if (response.ok) {
                    props.updateDeleteModalParent(false);
                }
            })
            .catch((error) => {
                setIsDisabled(false);
                console.log("The error is:", error);
            });
    };

    return (
        <div>
            <Modal show={props.showModal} className="bg-dark fancy-text-strong text-center">
                <Modal.Body>
                    <h1 className="fancy-text-stronger mb-4">Delete Announcement</h1>
                    <Form id="deleteForm">
                        <h3>Are you sure you want to remove this announcement?</h3>
                        <Button className="modal-close-btn" disabled={isDisabled} onClick={handleSubmit}>Yes, Remove Announcement</Button>
                        <Button className="modal-submit-btn" onClick={handleClose}>
                            Close
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default DeleteAnnouncement;
