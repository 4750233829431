import React, { Component } from 'react';
import { Row, Button } from 'react-bootstrap';
import { BaseUrl } from '../../baseRoute';
import DynaTable from '../Common/DynaTable';
import HrefButton from '../Common/HrefButton';
import CreateAnnouncement from '../Announcements/CreateAnnouncement';
import DeleteAnnouncement from '../Announcements/DeleteAnnouncement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';

interface Announcement {
    announcementId: string;
    text: string;
    createdBy: string;
    createdDateTime: string;
    expirationDateTime: string;
}

interface State {
    announcements: Announcement[];
    loading: boolean;
    announcementId: string;
    showCreateModal: boolean;
    showDeleteModal: boolean;
}

export class ManageAnnouncements extends Component<{}, State> {
    constructor(props: {}) {
        super(props);

        this.state = {
            announcements: [],
            loading: true,
            announcementId: '',
            showCreateModal: false,
            showDeleteModal: false,
        };
    }

    // Open/Close modals and refresh table from child components
    updateCreateModalParent(value: boolean) {
        this.setState({ showCreateModal: value });
        this.refreshTable();
    }
    updateDeleteModalParent(value: boolean) {
        this.setState({ showDeleteModal: value });
        this.refreshTable();
    }

    // Open/Close modals when clicking on buttons
    toggleCreateModal = (): void => {
        this.setState({ showCreateModal: !this.state.showCreateModal });
    }
    toggleRemoveModal = (announcementId: string): void => {
        this.setState({ showDeleteModal: !this.state.showDeleteModal, announcementId: announcementId });
    }

    componentDidMount() {
        this.refreshTable();
    }

    fetchAnnouncements(announcements: Announcement[]) {
        const columns = (): any[] => {
            return [
                {
                    title: 'Announcement',
                    render: (rowData: Announcement) => {
                        return <span>{rowData.text}</span>;
                    },
                    isFilterable: true,
                    field: 'text'
                },
                {
                    title: 'Expiration Date',
                    render: (rowData: Announcement) => {
                        return <span>{rowData.expirationDateTime}</span>;
                    },
                    isFilterable: true,
                    field: 'expirationDateTime',
                    contentClass: 'text-center'
                },
                {
                    title: 'Delete',
                    render: (rowData: Announcement) => {
                        return (
                            <Row>
                                <FontAwesomeIcon icon={faTrashCan} className="icon-delete" onClick={() => this.toggleRemoveModal(rowData.announcementId)} />
                            </Row>
                        );
                    },
                },
            ];
        }

        return (
            <>
                <DynaTable cols={columns()} data={announcements} striped bordered={false} hoverable sizeSearch={1} sizeButton={3} sizeFooter={4} />
            </>
        );
    }

    async refreshTable() {
        try {
            this.setState({ loading: true });
            const response = await fetch(`${BaseUrl.Path}api/announcements/get-all-announcements`);
            if (response.ok) {
                const data = await response.json();
                this.setState({ announcements: data, loading: false });
            } else {
                console.error('Failed to fetch data from the API');
            }
        } catch (error) {
            console.error('An error occurred while fetching data:', error);
        }
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.fetchAnnouncements(this.state.announcements);

        const createModal = this.state.showCreateModal ? <CreateAnnouncement showModal={this.state.showCreateModal} updateCreateModalParent={this.updateCreateModalParent.bind(this)} /> : null;
        const deleteModal = this.state.showDeleteModal ? <DeleteAnnouncement announcementId={this.state.announcementId} showModal={this.state.showDeleteModal} updateDeleteModalParent={this.updateDeleteModalParent.bind(this)} /> : null;

        return (
            <div className="m-2">
                <h2 className="page-title">Announcments</h2>
                <h5 className="text-right fancy-text mx-5">*Create a message that will be displayed for all users on the home page</h5>
                <Button className="custom-color-blue-gray m-1" variant="dark" onClick={this.toggleCreateModal}> Create New</Button>
                <HrefButton href="/Admin" label="Admin Workcenter"></HrefButton>
                <div>
                    {contents}
                    {createModal}
                    {deleteModal}
                </div>
            </div>
        );
    }
}