import React, { ChangeEvent, useEffect, useState, useRef } from 'react';
import { Button, Form, Row, Col, Modal } from 'react-bootstrap';
import UnassignChurch from '../Church/UnassignChurch';
import UnassignRegion from '../Region/UnassignRegion';
import AssignChurch from '../Church/AssignChurch';
import AssignRegion from '../Region/AssignRegion';
import { BaseUrl } from '../../baseRoute';
import '../Styles/ModalStyles.css';

interface EditUserProps {
    userId: string;
    showModal: boolean;
    updateEditModalParent: (value: boolean) => void;
}

const EditUser: React.FC<EditUserProps> = (props) => {
    const [validated, setValidated] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const [loading, setLoading] = useState(true);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [churchId, setChurchId] = useState('');
    const [churches, setChurches] = useState<[]>([]);
    const [regionId, setRegionId] = useState('');
    const [regions, setRegions] = useState<[]>([]);
    const [showUnassignChurchModal, setShowUnassignChurchModal] = useState(false);
    const [showUnassignRegionModal, setShowUnassignRegionModal] = useState(false);
    const [showAssignChurchModal, setShowAssignChurchModal] = useState(false);
    const [showAssignRegionModal, setShowAssignRegionModal] = useState(false);

    const userId = props.userId;
    const fullName = `${firstName} ${lastName}`;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        switch (name) {
            case 'firstName':
                setFirstName(value);
                break;
            case 'lastName':
                setLastName(value);
                break;
            case 'phoneNumber':
                setPhoneNumber(value);
                break;
            default:
                break;
        }
    };

    // Open/Close modals and refresh table from child components
    const updateUnassignChurchParent = (value: boolean):void => {
        setShowUnassignChurchModal( value );
        fetchUserById();
    }
    const updateUnassignRegionParent = (value: boolean): void => {
        setShowUnassignRegionModal(value);
        fetchUserById();
    }
    const updateAssignChurchParent = (value: boolean): void => {
        setShowAssignChurchModal(value);
        fetchUserById();
    }
    const updateAssignRegionParent = (value: boolean): void => {
        setShowAssignRegionModal(value);
        fetchUserById();
    }

    // Open/Close modals when clicking on buttons
    const toggleUnassignChurchModal = (churchId: string): void => {
        setShowUnassignChurchModal(!showUnassignChurchModal);
        setChurchId(churchId);
    }
    const toggleUnassignRegionModal = (regionId: string): void => {
        setShowUnassignRegionModal(!showUnassignRegionModal);
        setRegionId(regionId);
    }
    const toggleAssignChurchModal = (): void => {
        setShowAssignChurchModal(!showAssignChurchModal);
    }
    const toggleAssignRegionModal = (): void => {
        setShowAssignRegionModal(!showAssignRegionModal);
    }

    const handleClose = () => {
        props.updateEditModalParent(false);
    };

    const handleSubmit = () => {
        setIsDisabled(true);
        setValidated(true);
        if (formRef.current && formRef.current.checkValidity() === false) {
            setIsDisabled(false);
            return;
        }
        fetch(`${BaseUrl.Path}api/users/${userId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                id: userId,
                firstName: firstName,
                lastName: lastName,
                phoneNumber: phoneNumber
            })
        }).then((response) => {
            if (response.ok) {
                props.updateEditModalParent(false);
            }
        }).catch((error) => {
            setIsDisabled(false);
            console.log("The error is:", error);
        });
    };

    useEffect(() => {
        fetchUserById();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchUserById = () => {
        fetch(`${BaseUrl.Path}api/users/${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setFirstName(data.firstName);
                setLastName(data.lastName);
                setPhoneNumber(data.phoneNumber);
                setEmail(data.email);
                setChurches(data.churches);
                setRegions(data.regions);
                setLoading(false);
            }).catch(error => { console.log("The error is:", error) });
    };

    const fetchForm = () => {
        let assignedChurches = Object.keys(churches).length > 0 ?
            <Row className='mb-2'>
                <h6 className='text-left'>Assigned Churches</h6>
                {Object.entries(churches).map(([key, value]) => (
                    <Row key={value} className='mt-1'>
                        <Col>
                            <strong key={key}>{value}</strong>
                        </Col>
                        <Col>
                            <Button size="sm" className="custom-btn-remove" variant="outline-danger" onClick={() => toggleUnassignChurchModal(key)}>Remove Church</Button>
                        </Col>
                    </Row>
                ))}
            </Row> : null;
        let assignedRegions = Object.keys(regions).length > 0 ?
            <Row className='mb-2'>
                <h6 className='text-left'>Assigned Regions</h6>
                {Object.entries(regions).map(([key, value]) => (
                    <Row key={value} className='mt-1'>
                        <Col>
                            <strong key={key}>{value}</strong>
                        </Col>
                        <Col>
                            <Button size="sm" className="custom-btn-remove" variant="outline-danger" onClick={() => toggleUnassignRegionModal(key)}>Remove Region</Button>
                        </Col>
                    </Row>
                ))}
            </Row> : null;
        return (
            <Modal show={props.showModal}>
                <Modal.Body>
                    <h4 className="modal-header-text">Modify User</h4>
                    <Form id="edit-user-form" ref={formRef as React.RefObject<HTMLFormElement>} validated={validated}>
                        <Col className="mb-2">
                            <Form.Group as={Col} controlId="formFirstName">
                                <Form.Label className="modal-form-label-text">First Name</Form.Label>
                                <Form.Control required type="text" placeholder={firstName} name="firstName" value={firstName} onChange={handleChange} />
                                <Form.Control.Feedback type="invalid" className="modal-form-invalid-text"> Enter a name</Form.Control.Feedback>
                            </Form.Group>                        
                            <Form.Group as={Col} controlId="formLastName">
                                <Form.Label className="modal-form-label-text">Last Name</Form.Label>
                                <Form.Control required type="text" placeholder={lastName} name="lastName" value={lastName} onChange={handleChange} />
                                <Form.Control.Feedback type="invalid" className="modal-form-invalid-text"> Enter a name</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formPhoneNumber">
                                <Form.Label className="modal-form-label-text">Phone Number</Form.Label>
                                <br />
                                <Form.Text className="text-muted">Complete number with country code. </Form.Text>
                                <Form.Control required type="text" pattern="[0-9]{10,15}" placeholder={phoneNumber} name="phoneNumber" value={phoneNumber} onChange={handleChange} />
                                <Form.Control.Feedback type="invalid" className="modal-form-invalid-text">Number with no special characters, must be 10-15 digits.</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        {assignedChurches}
                        {assignedRegions}
                        <Button className="table-edit-btn" onClick={() => toggleAssignChurchModal()} variant="success">Assign Church</Button>
                        <Button className="table-edit-btn" onClick={() => toggleAssignRegionModal()} variant="success">Assign Region</Button>                     
                        <Button disabled={isDisabled} className="modal-submit-btn" onClick={handleSubmit}>
                            Save
                        </Button>
                        <Button className="modal-close-btn" onClick={handleClose}>
                            Close
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    };
    let contents = loading ? <p><em>Loading Form...</em></p> : fetchForm();
    let assignChurchModal = showAssignChurchModal ? <AssignChurch userId={userId} userName={fullName} email={email} showModal={showAssignChurchModal} updateAssignChurchParent={updateAssignChurchParent.bind(this)} /> : null;
    let assignRegionModal = showAssignRegionModal ? <AssignRegion userId={userId} userName={fullName} email={email} showModal={showAssignRegionModal} updateAssignRegionParent={updateAssignRegionParent.bind(this)} /> : null;
    let unassignRegionModal = showUnassignRegionModal ? <UnassignRegion userId={userId} regionId={regionId} userName={fullName} email={email} showModal={showUnassignRegionModal} updateUnassignRegionParent={updateUnassignRegionParent.bind(this)} /> : null;
    let unassignChurchModal = showUnassignChurchModal ? <UnassignChurch userId={userId} churchId={churchId} userName={fullName} email={email} showModal={showUnassignChurchModal} updateUnassignChurchParent={updateUnassignChurchParent.bind(this)} /> : null;

    return (
        <div>
            {contents}
            {unassignChurchModal}
            {unassignRegionModal}
            {assignChurchModal}
            {assignRegionModal}
        </div>);
};

export default EditUser;
