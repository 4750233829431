import React from 'react';
import { Tab, Tabs, Row, Col } from 'react-bootstrap';
import { BaseUrl } from '../baseRoute';
import BarChartWrapper from '../components/ReportViews/BarChartWrapper';
import './Styles/Reports.css';
import './Styles/Region.css';
import ValueDisplayCard from 'components/Common/ValueDisplayCard';
import SpinnerComp from './Common/SpinnerComp';
import PastorStatusReport from '../components/ReportViews/PastorStatusReport';

export class Region extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            regionName: 'N/A',
            regionId: '',
            churchList: [],
            loading: true,
            loadingChurch: true,
            services: [],
            graphData: [],
            toDate: "",
            fromDate: ""
        };
    }

    componentDidMount() {
        this.fetchOverseerName();
        this.refreshChurches();
        this.fetchGraphData(this.state.toDate, this.state.fromDate);
    }

    updateToDateGrandparent(value) {
        this.setState({ toDate: value });
        this.fetchGraphData(value, this.state.fromDate);
    }

    updateFromDateGrandparent(value) {
        this.setState({ fromDate: value });
        this.fetchGraphData(this.state.toDate, value);
    }

    fetchRegionHeader(regionName, overseer) {
        return (
            <div>
                <h1 className="fancy-text-stronger text-center mt-2"><strong>{regionName}</strong></h1>
                <h5 className=" fancy-text m-1">Proudly served by <strong>{overseer}</strong></h5>
            </div>
        );
    }
    renderChurchList(churchList) {
        return (
            <Col>
                <h5 className="text-center fancy-text mt-2 mb-3">Select church name from list to view details</h5>
                <ValueDisplayCard items={churchList} />
            </Col>
        );
    }

    async fetchOverseerName() {
        const id = this.props.match.params.regionId;
        fetch(`${BaseUrl.Path}api/regionaloverseer/region-overseer/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({ regionName: data.regionName, overseer: data.overseerName, loading: false })
            })
            .catch(error => { console.log("The error is:", error) })
    }
    async fetchStatusReport(days) {
        const regionId = this.props.match.params.regionId;
        const response = await fetch(`${BaseUrl.Path}api/regions/region-status-report/${regionId}/${days}`);
        const data = await response.json();
        this.setState({ statusReport: data, statusReportLoading: false });
    }
    async refreshChurches() {
        const id = this.props.match.params.regionId;
        const response = await fetch(`${BaseUrl.Path}api/churches/churches-by-region/${id}`);
        const data = await response.json();
        this.setState({ churchList: data, loadingChurch: false });
    }
    async fetchGraphData(toDate, fromDate) {
        const id = this.props.match.params.regionId;
        let today = new Date();
        if (toDate === "") {
            toDate = today.getFullYear() + '-01-01';
        }
        if (fromDate === "") {
            let month = (today.getMonth() + 1).toString().padStart(2, '0');
            let day = today.getDate().toString().padStart(2, '0');
            fromDate = today.getFullYear() + '-' + month + '-' + day;
        }
        const url = new URL(`${BaseUrl.Path}api/report/custom-region-attendance/${id}`);
        url.searchParams.append('startDate', toDate);
        url.searchParams.append('endDate', fromDate);

       fetch(url, {
        method: 'GET',
            headers: {'Content-Type': 'application/json; charset=utf-8'}
       }).then((response) => response.json()).then((data) => {
           this.setState({ graphData: data, toDate: toDate, fromDate: fromDate })
       }).catch(error => { console.log("The error is:", error) });
    };

    render() {
        const valueDisplayData = this.state.churchList.map(obj => (
            {
                name: obj.name,
                link: `/church/${obj.churchId}`,
                children: [{
                    label: 'Location',
                    value: obj.location
                }]
            }));
        const id = this.props.match.params.regionId;
        let pageHeader = this.state.loading
            ? <SpinnerComp displayText='Trying to fetch page header' />
            : this.fetchRegionHeader(this.state.regionName, this.state.overseer);
        let churchNavList = this.state.loadingChurch
            ? <SpinnerComp displayText='Loading churches in region.....Please wait....' />
            : this.renderChurchList(valueDisplayData);        
        
        return (
            <div>   
                <Row className="custom-horizontal-margin">
                {pageHeader}
                {churchNavList}
                    <Col md={9} xl={9} className="p-2 mt-2 rounded">
                        <Tabs defaultActiveKey="home" fill>
                            <Tab eventKey="home" title="Status Report" tabClassName="color-tabs-2">
                                <PastorStatusReport regionId={id}/>
                            </Tab>
                            <Tab eventKey="tab2" title="Attendance" tabClassName="color-tabs-2">
                                <div className="custom-color-green-light pt-2">
                                    <BarChartWrapper graphData={this.state.graphData} updateToDateGrandparent={this.updateToDateGrandparent.bind(this)} updateFromDateGrandparent={this.updateFromDateGrandparent.bind(this)} toDate={this.state.toDate} fromDate={this.state.fromDate} />
                                </div>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </div>
        );
    }
}