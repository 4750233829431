import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Col, Row } from 'react-bootstrap';
import { BaseUrl } from '../../baseRoute';
import '../Styles/Reports.css';
import RegionOverviewChart from '../Common/RegionOverviewChart';
import SpinnerComp from '../Common/SpinnerComp';
/*Report Components*/
import DoughnutChartWrapper from './DoughnutChartWrapper';
import BarChartWrapper from './BarChartWrapper';
import ChurchServiceReport from './ChurchServiceReport';
import ComplianceReport from './ComplianceReport';
import { ReportChurch } from './ReportChurch';
import { ReportRegion } from './ReportRegion';

interface MetricsData {
    labels: string[];
    data: number[];
    colors: string[];
}

const Reports: React.FC = () => {
    const [overviewChartData, setOverviewChartData] = useState<any[]>([]);
    const [ageBreakdownData, setAgeBreakdownData] = useState<MetricsData>({
        labels: [],
        data: [],
        colors: [],
    });
    const [serviceTypeBreakdownData, setServiceTypeBreakdownData] = useState<MetricsData>({
        labels: [],
        data: [],
        colors: [],
    });
    const [barGraphData, setBarGraphData] = useState<any[]>([]);
    const [loadingOverviewChart, setLoadingOverviewChart] = useState(true);
    const [loadingAgeBreakdownChart, setLoadingAgeBreakdownChart] = useState(true);
    const [loadingServiceTypeBreakdownChart, setLoadingServiceTypeBreakdownChart] = useState(true);
    const [loadingBarGraphChart, setLoadingBarGraphChart] = useState(true);
    const [toDate, setToDate] = useState<string>('');
    const [fromDate, setFromDate] = useState<string>('');

    useEffect(() => {
        fetchReportOverviewChart();
        fetchAgeBreakdownChart(toDate, fromDate);
        fetchServiceTypeBreakdownChart(toDate, fromDate);
        fetchBarChart(toDate, fromDate);
    }, [toDate, fromDate]);

    const updateToDateGrandparent = (value: string) => {
        setToDate(value);
    };

    const updateFromDateGrandparent = (value: string) => {
        setFromDate(value);
    };

    const fetchReportOverviewChart = async () => {
        try {
            const response = await fetch(`${BaseUrl.Path}api/report/region-overview-chart`);
            const data = await response.json();
            setOverviewChartData(data);
            setLoadingOverviewChart(false);
        } catch (error) {
            console.error('Error fetching overview chart:', error);
        }
    };

    const fetchAgeBreakdownChart = async (toDate: string, fromDate: string) => {
        let today = new Date();
        if (toDate === '') {
            toDate = `${today.getFullYear()}-01-01`;
        }
        if (fromDate === '') {
            let month = (today.getMonth() + 1).toString().padStart(2, '0');
            let day = today.getDate().toString().padStart(2, '0');
            fromDate = `${today.getFullYear()}-${month}-${day}`;
        }

        try {
            const url = new URL(`${BaseUrl.Path}api/report/age-breakdown`);
            url.searchParams.append('startDate', toDate);
            url.searchParams.append('endDate', fromDate);

            const response = await fetch(url.toString());
            const data = await response.json();
            setAgeBreakdownData(data);
            setLoadingAgeBreakdownChart(false);
        } catch (error) {
            console.error('Error fetching age breakdown chart:', error);
        }
    };

    const fetchServiceTypeBreakdownChart = async (toDate: string, fromDate: string) => {
        let today = new Date();
        if (toDate === '') {
            toDate = `${today.getFullYear()}-01-01`;
        }
        if (fromDate === '') {
            let month = (today.getMonth() + 1).toString().padStart(2, '0');
            let day = today.getDate().toString().padStart(2, '0');
            fromDate = `${today.getFullYear()}-${month}-${day}`;
        }

        try {
            const url = new URL(`${BaseUrl.Path}api/report/service-type-breakdown/church/`);
            url.searchParams.append('startDate', toDate);
            url.searchParams.append('endDate', fromDate);

            const response = await fetch(url.toString());
            const data = await response.json();
            setServiceTypeBreakdownData(data);
            setLoadingServiceTypeBreakdownChart(false);
        } catch (error) {
            console.error('Error fetching service type breakdown chart:', error);
        }
    };

    const fetchBarChart = async (toDate: string, fromDate: string) => {
        let today = new Date();
        if (toDate === '') {
            toDate = `${today.getFullYear()}-01-01`;
        }
        if (fromDate === '') {
            let month = (today.getMonth() + 1).toString().padStart(2, '0');
            let day = today.getDate().toString().padStart(2, '0');
            fromDate = `${today.getFullYear()}-${month}-${day}`;
        }

        try {
            const url = new URL(`${BaseUrl.Path}api/report/get-all-attendance`);
            url.searchParams.append('startDate', toDate);
            url.searchParams.append('endDate', fromDate);

            const response = await fetch(url.toString());
            const data = await response.json();
            setBarGraphData(data);
            setLoadingBarGraphChart(false);
        } catch (error) {
            console.error('Error fetching bar chart:', error);
        }
    };

    const regionOverviewChart = loadingOverviewChart ? (
        <SpinnerComp displayText="Loading Region details" />
    ) : (
        <RegionOverviewChart items={overviewChartData} />
    );

    const barGraphChart = loadingBarGraphChart ? (
        <SpinnerComp displayText="Loading Chart" />
    ) : (
        <BarChartWrapper
            graphData={barGraphData}
            updateToDateGrandparent={updateToDateGrandparent}
            updateFromDateGrandparent={updateFromDateGrandparent}
            toDate={toDate}
            fromDate={fromDate}
        />
    );

    const ageBreakDownChart = loadingAgeBreakdownChart ? (
        <SpinnerComp displayText="Loading Chart" />
    ) : (
        <DoughnutChartWrapper
            data={ageBreakdownData}
            chartTitle="Attendance by Age"
            updateToDateGrandparent={updateToDateGrandparent}
            updateFromDateGrandparent={updateFromDateGrandparent}
            toDate={toDate}
            fromDate={fromDate}
        />
    );

    const serviceTypeBreakDownChart = loadingServiceTypeBreakdownChart ? (
        <SpinnerComp displayText="Loading Chart" />
    ) : (
        <DoughnutChartWrapper
            data={serviceTypeBreakdownData}
            chartTitle="Attendance by Service Type"
            updateToDateGrandparent={updateToDateGrandparent}
            updateFromDateGrandparent={updateFromDateGrandparent}
            toDate={toDate}
            fromDate={fromDate}
        />
    );

    return (
        <div>
            <h1 className="text-center fancy-text-strong">Reports</h1>
            <Tabs id="report-menu" defaultActiveKey="compliance-report" className="nav-fill">
                <Tab eventKey="first" tabClassName="color-tabs-2" title="Overview">
                    <Row>
                        <Col>{regionOverviewChart}</Col>
                        <Col xl={3} className="col-min-w-400">
                            <h6 className="text-center mt-4">
                                Select tabs to search regions and churches
                            </h6>
                            <Tabs defaultActiveKey="regionsTab" id="content-tabs" className="nav-fill">
                                <Tab eventKey="regionsTab" title="Region" tabClassName="color-tabs-2">
                                    <ReportRegion />
                                </Tab>
                                <Tab eventKey="churchTab" title="Church" tabClassName="color-tabs-2">
                                    <ReportChurch />
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="compliance-report" tabClassName="color-tabs-2" title="Compliance">
                    <ComplianceReport chartTitle="Compliance Report" />
                </Tab>
                <Tab eventKey="table-1" tabClassName="color-tabs-2" title="Service Report">
                    <ChurchServiceReport />
                </Tab>
                <Tab eventKey="chart-2" tabClassName="color-tabs-2" title="Attendance History">
                    {barGraphChart}
                </Tab>
                <Tab eventKey="chart-3" tabClassName="color-tabs-2" title="Age Breakdown">
                    {ageBreakDownChart}
                </Tab>
                <Tab eventKey="chart-4" tabClassName="color-tabs-2" title="Service Breakdown">
                    {serviceTypeBreakDownChart}
                </Tab>
            </Tabs>
        </div>
    );
};

export default Reports;
