import React, { ChangeEvent, useEffect, useState, useRef } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { BaseUrl } from '../../baseRoute';
import '../Styles/ModalStyles.css';

interface Church {
    churchId: string;
    name: string;
}

interface Props {
    userId: string;
    userName: string;
    email: string;
    showModal: boolean;
    updateAssignChurchParent: (value: boolean) => void;
}

const AssignChurch: React.FC<Props> = (props) => {
    const [validated, setValidated] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const [churchList, setChurchList] = useState<Church[]>([]);
    const [churchId, setChurchId] = useState('');

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        if (name === 'churchId') {
            setChurchId(value);
        }
    };

    const handleSubmit = () => {
        setIsDisabled(true);
        setValidated(true);
        if (formRef.current && formRef.current.checkValidity() === false) {
            setIsDisabled(false);
            return;
        }
        fetch(`${BaseUrl.Path}api/users/Assign-Church`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                userId: props.userId,
                churchId: churchId
            })
        }).then((response) => {
            if (response.ok) {
                props.updateAssignChurchParent(false);
            }
        }).catch((error) => {
            setIsDisabled(false);
            console.log("The error is:", error);
        });
    };

    const handleClose = () => {
        props.updateAssignChurchParent(false);
    };

    useEffect(() => {
        fetchChurches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function fetchChurches() {
        const response = await fetch(`${BaseUrl.Path}api/churches/unassigned`);
        const data = await response.json();
        setChurchList(data);
    };

    return (
        <div>
            <Modal show={props.showModal} className="bg-dark fancy-text-strong text-center">
                <Modal.Body>
                    <h2 className="mb-3 border-bottom border-dark"><strong>Assign Church</strong></h2>
                    <h5 className="mb-4 modal-form-label-text text-center">Select a church from the dropdown you wish to assign to <strong>{props.email}</strong>.</h5>
                    <h5 className="mb-4 modal-form-label-text text-center">Proceeding will make <strong> {props.userName}</strong> a pastor for selected church.</h5>
                    <Form id="createForm" ref={formRef as React.RefObject<HTMLFormElement>} validated={validated}>
                        <Form.Group controlId="formChurch" className="mb-4">                            
                            <Form.Control as="select"
                                    required
                                    value={churchId}
                                    onChange={handleChange}
                                    name="churchId">
                                    isInvalid={churchId === ''}
                                    <option key={0} value={''}>Choose Church...</option>
                                    {churchList.map(c =>
                                        <option key={c.churchId} value={c.churchId}>{c.name}</option>
                                    )}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid" className="modal-form-invalid-text">Select a church to assign to user</Form.Control.Feedback>
                        </Form.Group>
                        <Button disabled={isDisabled} className="modal-submit-btn" onClick={handleSubmit}>
                            Yes, Assign Church
                        </Button>
                        <Button className="modal-close-btn" onClick={handleClose}>
                            Close
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AssignChurch;