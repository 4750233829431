import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { BaseUrl } from '../../baseRoute';
import '../Styles/ModalStyles.css';

interface Props {
    userId: string;
    regionId: string;
    userName: string;
    email: string;
    showModal: boolean;
    updateUnassignRegionParent: (value: boolean) => void;
}

const UnassignRegion: React.FC<Props> = (props) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [regionName, setRegionName] = useState('');

    const handleSubmit = () => {
        setIsDisabled(true);
        fetch(`${BaseUrl.Path}api/users/Unassign-Region`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                userId: props.userId,
                regionId: props.regionId
            })
        }).then((response) => {
            if (response.ok) {
                props.updateUnassignRegionParent(false);
            }
        }).catch((error) => {
            setIsDisabled(false);
            console.log("The error is:", error);
        });
    };

    const handleClose = () => {
        props.updateUnassignRegionParent(false);
    };

    useEffect(() => {
        fetchRegionById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function fetchRegionById() {
        const id = props.regionId
        if (!id) {
            setRegionName("N/A");
            setIsDisabled(true);
            return;
        }
        fetch(`${BaseUrl.Path}api/regions/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then((response) => response.json())
        .then((data) => {
            setRegionName(data.regionName);
        }).catch(error => { console.log("Something we wrong! Unable to fetch user's name'. Error:", error) });
    };

    return (
        <div>
            <Modal show={props.showModal} className="bg-dark fancy-text-strong text-center">
                <Modal.Body>
                    <h2 className="mb-3 border-bottom border-dark"><strong>Unassign Region</strong></h2>
                    <h4 className="mb-4 modal-form-label-text text-center">Are you sure you want to remove <strong>{regionName}</strong> from <strong>{props.email}</strong>.</h4>
                    <h5 className="mb-4 modal-form-label-text text-center">Proceeding will remove <strong> {props.userName}</strong> as the regional overseer for <strong>{regionName}</strong>.</h5>
                    <Button disabled={isDisabled} className="modal-submit-btn" onClick={handleSubmit}>
                        Yes, Unassign Region
                    </Button>
                    <Button className="modal-close-btn" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default UnassignRegion;