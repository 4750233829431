import React from 'react';
import { Col, Accordion, Row} from 'react-bootstrap';
import '../Styles/ValueDisplayAccordion.css';
import HrefButton from '../Common/HrefButton';

interface ValueDisplayData {
    name: string;
    link: string;
    children: {
        label: string,
        value: string
    }[];
}

type MyProps = {
    items: ValueDisplayData[];
};

const ValueDisplayAccordion: React.FC<MyProps> = ({ items }) => {
    if (items.length === 0) {
        return <h2 className="text-center my-5">Search results returned no records...</h2>;
    }

    return (
        <div className="value-display-accordion-div rounded p-2">
            <h5 className="text-center value-display-accordion-title">Select to expand details</h5>
            <Accordion className="p-1 custom-color-green-fade">
                {items.map((item, index) => (
                    <div key={index}>
                        <Accordion.Item eventKey={item.name + index} className="value-display-accordion-item">
                            <Accordion.Header className="value-display-accordion-header-text">{item.name}</Accordion.Header>
                            <Accordion.Body className="value-display-accordion-body">
                                {item.children.map((child, i) => (
                                    <Row key={i} className="value-display-accordion-row">
                                        <Col>{child.label}</Col>
                                        <Col className="value-display">{child.value}</Col>
                                    </Row>
                                ))}
                                {item.link === "" ? null : (
                                    <Row className="justify-content-center">
                                        <HrefButton classStyle="value-display-accordion-btn" href={item.link} label="View Details" />
                                    </Row>
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                    </div>
                ))}
            </Accordion>
        </div>
    );
};

export default ValueDisplayAccordion;