import React from 'react';

export default class SearchBox extends React.Component {
    handleSearchChange(event) {
        this.props.updateSearchParent(event.target.value);
    }

    render() {
        return (
            <div className="custom-color-blue-gray rounded p-2">
                <input type="text" className="form-control" placeholder="Search" value={this.props.searchText} onChange={this.handleSearchChange.bind(this)} />
            </div>
        )
    }
}