import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Row, Col, Modal } from 'react-bootstrap';
import authService from '../api-authorization/AuthorizeService';
import { BaseUrl } from '../../baseRoute';
import '../Styles/ModalStyles.css';

interface ChurchList {
    name: string;
    location: string;
    regionName: string;
}

interface CreateServiceProps {
    showCreateServiceModal: boolean;
    updateCreateServiceModalParent: (value: boolean) => void;
    updateShowConfirmationParent: (value: boolean) => void;
}

const CreateService: React.FC<CreateServiceProps> = (props) => {
    const [churchList, setChurchList] = useState <ChurchList[]>([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [loading, setLoading] = useState(true);
    // Form fields
    const [validated, setValidated] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);
    const [serviceType, setServiceType] = useState('Prayer');
    const [menAttendance, setMenAttendance] = useState(0);
    const [womenAttendance, setWomenAttendance] = useState(0);
    const [youthMaleAttendance, setYouthMaleAttendance] = useState(0);
    const [youthFemaleAttendance, setYouthFemaleAttendance] = useState(0);
    const [childrenMaleAttendance, setChildrenMaleAttendance] = useState(0);
    const [childrenFemaleAttendance, setChildrenFemaleAttendance] = useState(0);
    const [teenMaleAttendance, setTeenMaleAttendance] = useState(0);
    const [teenFemaleAttendance, setTeenFemaleAttendance] = useState(0);
    const [baptisms, setBaptisms] = useState(0);
    const [bornAgain, setBornAgain] = useState(0);
    const [offeringCollected, setOfferingCollected] = useState(0);
    const [serviceDate, setServiceDate] = useState('');
    const [churchId, setChurchId] = useState('');
    const [submittedByUserId, setSubmittedByUserId] = useState('');

    useEffect(() => {
        getUserChurch();
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        switch (name) {
            case 'serviceType':
                setServiceType(value);
                break;
            case 'menAttendance':
                setMenAttendance(Number(value));
                break;
            case 'womenAttendance':
                setWomenAttendance(Number(value));
                break;
            case 'youthMaleAttendance':
                setYouthMaleAttendance(Number(value));
                break;
            case 'youthFemaleAttendance':
                setYouthFemaleAttendance(Number(value));
                break;
            case 'childrenMaleAttendance':
                setChildrenMaleAttendance(Number(value));
                break;
            case 'childrenFemaleAttendance':
                setChildrenFemaleAttendance(Number(value));
                break;
            case 'teenMaleAttendance':
                setTeenMaleAttendance(Number(value));
                break;
            case 'teenFemaleAttendance':
                setTeenFemaleAttendance(Number(value));
                break;
            case 'baptisms':
                setBaptisms(Number(value));
                break;
            case 'bornAgain':
                setBornAgain(Number(value));
                break;
            case 'offeringCollected':
                setOfferingCollected(Number(value));
                break;
            case 'serviceDate':
                setServiceDate(value);
                break;
            case 'churchId':
                setChurchId(value);
                break;
            case 'submittedByUserId':
                setSubmittedByUserId(value);
                break;
            default:
                break;
        }
    };

    const handleClose = () => {
        props.updateCreateServiceModalParent(false);
    };

    const handleSubmit = () => {
        setIsDisabled(true);
        setValidated(true);
        if (formRef.current && formRef.current.checkValidity() === false) {
            setIsDisabled(false);
            return;
        }
        fetch(`${BaseUrl.Path}api/services/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                serviceType: serviceType,
                menAttendance: menAttendance,
                womenAttendance: womenAttendance,
                youthMaleAttendance: youthMaleAttendance,
                youthFemaleAttendance: youthFemaleAttendance,
                teenMaleAttendance: teenMaleAttendance,
                teenFemaleAttendance: teenFemaleAttendance,
                childrenMaleAttendance: childrenMaleAttendance,
                childrenFemaleAttendance: childrenFemaleAttendance,
                bornAgain: bornAgain,
                baptisms: baptisms,
                offeringCollected: offeringCollected,
                serviceDate: serviceDate,
                churchId: churchId,
                submittedByUserId: submittedByUserId
            })
        }).then((response) => {
            if (response.ok) {
                props.updateCreateServiceModalParent(false);
                props.updateShowConfirmationParent(true);
            }
        }).catch(error => {
            setIsDisabled(false);
            console.log("Not able to create church. Error:", error)
        });
    };

    const getUserChurch = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();
        if (user) {
            const id = user.sub;
            fetch(`${BaseUrl.Path}api/churches/user-churches/${id}`, {
                method: 'GET',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
                .then(response => response.json())
                .then(data => {
                    if (data.length > 0) {
                        if (data.length === 1) {
                            setChurchId(data[0].churchId);
                        }
                        setLoading(false);
                        setSubmittedByUserId(id);
                        setChurchList(data);
                    } else {
                        console.log("User has no churches");
                    }
                })
                .catch(error => console.log("Error returning churches for user:", error));
        }
    };

    const renderForm = () => {
        return (
            <Modal show={props.showCreateServiceModal}>
                <Modal.Body className="custom-color-gray fancy-text text-left">
                    {form}
                    <Form id="serviceForm" ref={formRef as React.RefObject<HTMLFormElement>} validated={validated}>
                        <Row>
                            <Form.Group className="mb-3" controlId="formServiceType">
                                <Form.Label>Service Type ~ Aina ya Ibada</Form.Label>
                                <Form.Control as="select"
                                    required
                                    value={serviceType}
                                    onChange={handleChange}
                                    name="serviceType">
                                    <option key="PrayerService" value="Prayer">Prayer ~ Sala</option>
                                    <option key="MidweekService" value="MidWeek">Mid-Week ~ Katikati ya wiki</option>
                                    <option key="SundayAMService" value="SundayAm">Sunday AM ~ Ibada ya Jumapili asubuhi</option>
                                    <option key="SundayPMService" value="SundayPm">Sunday PM ~ Ibada ya Jumapili jioni</option>
                                    <option key="OtherService" value="Other">Other ~ nyingine</option>
                                    )
                                </Form.Control>
                                <Form.Control.Feedback type="invalid" className="modal-form-invalid-text">Select service type</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <div className="border border-dark p-2 mb-1">
                            <h5 className="text-center fancy-text-strong">Attendance</h5>
                            <h5 className="text-center fancy-text-strong">idadi ya watu</h5>
                            <Row className="mb-2">
                                <Form.Group as={Col} controlId="formMenAttendance">
                                    <Form.Label>Men ~ Wanaume</Form.Label>
                                    <Form.Control required type="number" min={0} max={500} name="menAttendance" value={menAttendance} onChange={handleChange} />
                                    <Form.Control.Feedback type="invalid" className="modal-form-invalid-text">Enter number between 0 & 500</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formWomenAttendance">
                                    <Form.Label>Women ~ Wanawake</Form.Label>
                                    <Form.Control required type="number" min={0} max={500} name="womenAttendance" value={womenAttendance} onChange={handleChange} />
                                    <Form.Control.Feedback type="invalid" className="modal-form-invalid-text">Enter number between 0 & 500</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} controlId="formYouthMaleAttendance">
                                    <Form.Label>Youth Male ~ Vijana</Form.Label>
                                    <Form.Control required type="number" min={0} max={500} name="youthMaleAttendance" value={youthMaleAttendance} onChange={handleChange} />
                                    <Form.Text className="text-muted">Age 19-26</Form.Text>
                                    <Form.Control.Feedback type="invalid" className="modal-form-invalid-text">Enter number between 0 & 500</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formYouthFemaleAttendance">
                                    <Form.Label>Youth Female ~ Vijana</Form.Label>
                                    <Form.Control required type="number" min={0} max={500} name="youthFemaleAttendance" value={youthFemaleAttendance} onChange={handleChange} />
                                    <Form.Text className="text-muted">Age 19-26</Form.Text>
                                    <Form.Control.Feedback type="invalid" className="modal-form-invalid-text">Enter number between 0 & 500</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} controlId="formteenMaleAttendance">
                                    <Form.Label>Teen Male</Form.Label>
                                    <Form.Control required type="number" min={0} max={500} name="teenMaleAttendance" value={teenMaleAttendance} onChange={handleChange} />
                                    <Form.Text className="text-muted">Age 11-18</Form.Text>
                                    <Form.Control.Feedback type="invalid" className="modal-form-invalid-text">Enter number between 0 & 500</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formteenFemaleAttendance">
                                    <Form.Label>Teen Female</Form.Label>
                                    <Form.Control required type="number" min={0} max={500} name="teenFemaleAttendance" value={teenFemaleAttendance} onChange={handleChange} />
                                    <Form.Text className="text-muted">Age 11-18</Form.Text>
                                    <Form.Control.Feedback type="invalid" className="modal-form-invalid-text">Enter number between 0 & 500</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} controlId="formChildrenMaleAttendance">
                                    <Form.Label>Child Male ~ Watoto</Form.Label>
                                    <Form.Control required type="number" min={0} max={500} name="childrenMaleAttendance" value={childrenMaleAttendance} onChange={handleChange} />
                                    <Form.Text className="text-muted">Younger than 11</Form.Text>
                                    <Form.Control.Feedback type="invalid" className="modal-form-invalid-text">Enter number between 0 & 500</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formChildrenFemaleAttendance">
                                    <Form.Label>Child Female ~ Watoto</Form.Label>
                                    <Form.Control required type="number" min={0} max={500} name="childrenFemaleAttendance" value={childrenFemaleAttendance} onChange={handleChange} />
                                    <Form.Text className="text-muted">Younger than 11</Form.Text>
                                    <Form.Control.Feedback type="invalid" className="modal-form-invalid-text">Enter number between 0 & 500</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                        </div>
                        <Row className="mb-2">
                            <Form.Group as={Col} controlId="formBaptisms">
                                <Form.Label>Baptisms</Form.Label>
                                <Row ><Form.Label>Ubatizo</Form.Label></Row>
                                <Form.Control required type="number" min={0} max={250} name="baptisms" value={baptisms} onChange={handleChange} />
                                <Form.Control.Feedback type="invalid" className="modal-form-invalid-text">Enter number between 0 & 250</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formBornAgain">
                                <Form.Label>Born Again</Form.Label>
                                <Row ><Form.Label>Wokovu</Form.Label></Row>
                                <Form.Control required type="number" min={0} max={250} name="bornAgain" value={bornAgain} onChange={handleChange} />
                                <Form.Control.Feedback type="invalid" className="modal-form-invalid-text">Enter number between 0 & 250</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formOfferingCollected">
                                <Form.Label>Offering</Form.Label>
                                <Row ><Form.Label>Sadaka na zaka</Form.Label></Row>
                                <Form.Control required type="number" min={0} max={100000} name="offeringCollected" value={offeringCollected} onChange={handleChange} />
                                <Form.Control.Feedback type="invalid" className="modal-form-invalid-text">Enter number between 0 & 100,000</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formServiceDate">
                                <Form.Label>Service Date ~ Tarehe</Form.Label>
                                <Form.Control required type="date" name='serviceDate' value={serviceDate} onChange={handleChange} />
                                <Form.Control.Feedback type="invalid" className="modal-form-invalid-text">Enter the service date</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Button disabled={isDisabled} className="modal-submit-btn" onClick={handleSubmit}>
                            Submit Changes
                        </Button>
                        <Button className="modal-close-btn" onClick={handleClose}>
                            Close
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    };

    const churchDropdown = () => (
        <Form.Group controlId="formChurchSelect">
            <Form.Label>Church</Form.Label>
            <Form.Control as="select"
                value={churchId}
                onChange={handleChange}
                name="churchId"
                required
                isInvalid={churchId === ''}>
                <option key={0} value={''}>Choose Church...</option>
                {churchList.map((c: any, index: number) =>
                    <option key={index} value={c.churchId}>{c.name}</option>
                )}
            </Form.Control>
            <Form.Control.Feedback type="invalid" className="modal-form-invalid-text">Select a church</Form.Control.Feedback>
        </Form.Group>
    );

    const churchLabel = () => (
        <>
            {churchList.map((c: ChurchList, index: number) =>
                <h4 key={index} className="text-center"><strong>{c.name}</strong></h4>
            )}
        </>
    );

    let form = churchList.length > 1 ? churchDropdown() : churchLabel();
    let contents = loading
        ? <p><em>Loading Form...</em></p>
        : renderForm();

    return (<>{contents}</>);
};

export default CreateService;
