import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { BaseUrl } from '../../baseRoute';

interface DeleteChurchProps {
    churchId: string;
    churchName: string;
    showModal: boolean;
    updateDeleteModalParent: (value: boolean) => void;
}

const DeleteChurch: React.FC<DeleteChurchProps> = (props) => {
    const [isDisabled, setIsDisabled] = useState(false);

    const handleClose = () => {
        props.updateDeleteModalParent(false);
    };

    const handleSubmit = () => {
        setIsDisabled(true);
        fetch(`${BaseUrl.Path}api/churches/Delete-Church`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({
                churchId: props.churchId,
            }),
        }).then((response) => {
            if (response.ok) {
                props.updateDeleteModalParent(false);
            }
        }).catch((error) => {
            setIsDisabled(false);
            console.log("The error is:", error);
        });
    };

    return (
        <div>
            <Modal show={props.showModal} className="bg-dark fancy-text-strong text-center">
                <Modal.Body>
                    <h1 className="fancy-text-stronger mb-4">Delete Church</h1>
                    <Form id="deleteForm" onSubmit={handleSubmit}>
                        <h3>Are you sure you want to remove <strong>{props.churchName}</strong> church?</h3>
                        <h6 className="mb-3 mt-4">**Only Blake will be able to retrieve this church**</h6>
                        <Button className="modal-close-btn" disabled={isDisabled} onClick={handleSubmit}>Yes, Remove Church</Button>
                        <Button className="modal-submit-btn" onClick={handleClose}>
                            Close
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default DeleteChurch;
