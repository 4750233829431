import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Row, Col, Modal } from 'react-bootstrap';
import '../Styles/Home.css';
import authService from '../api-authorization/AuthorizeService';
import { BaseUrl } from '../../baseRoute';

interface ChurchList {
    name: string;
    location: string;
    regionName: string;
}

interface CreatePastoralCareProps {
    showCreatePastoralCareModal: boolean;
    updateCreatePastoralCareModalParent: (value: boolean) => void;
    updateShowConfirmationParent: (value: boolean) => void;
}

const CreatePastoralCare: React.FC<CreatePastoralCareProps> = (props) => {
    const [churchList, setChurchList] = useState<ChurchList[]>([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [loading, setLoading] = useState(true);
    // Form fields
    const [validated, setValidated] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);
    const [newBelieverContacts, setNewBelieverContacts] = useState(0);
    const [newBelieverFollowUp, setNewBelieverFollowUp] = useState(0);
    const [nonBelieverContacts, setNonBelieverContacts] = useState(0);
    const [nonBelieverFollowUp, setNonBelieverFollowUp] = useState(0);
    const [submitDate, setSubmitDate] = useState('');
    const [churchId, setChurchId] = useState('');
    const [submittedByUserId, setSubmittedByUserId] = useState('');

    useEffect(() => {
        getUserChurch();
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        switch (name) {
            case 'newBelieverContacts':
                setNewBelieverContacts(Number(value));
                break;
            case 'newBelieverFollowUp':
                setNewBelieverFollowUp(Number(value));
                break;
            case 'nonBelieverContacts':
                setNonBelieverContacts(Number(value));
                break;
            case 'nonBelieverFollowUp':
                setNonBelieverFollowUp(Number(value));
                break;
            case 'submitDate':
                setSubmitDate(value);
                break;
            case 'churchId':
                setChurchId(value);
                break;
            case 'submittedByUserId':
                setSubmittedByUserId(value);
                break;
            default:
                break;
        }
    };

    const handleClose = () => {
        props.updateCreatePastoralCareModalParent(false);
    };

    const handleSubmit = () => {
        setIsDisabled(true);
        setValidated(true);
        if (formRef.current && formRef.current.checkValidity() === false) {
            setIsDisabled(false);
            return;
        }
        fetch(`${BaseUrl.Path}api/churchmetrics/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                believerContacts: newBelieverContacts,
                believerFollowUp: newBelieverFollowUp,
                nonBelieverContacts: nonBelieverContacts,
                nonBelieverFollowUp: nonBelieverFollowUp,
                submitDate: submitDate,
                churchId: churchId,
                submittedByUserId: submittedByUserId
            })
        }).then((response) => {
            if (response.ok) {
                props.updateCreatePastoralCareModalParent(false);
                props.updateShowConfirmationParent(true);
            }
        }).catch(error => {
            setIsDisabled(false);
            console.log("Not able to submit form Error:", error)
        });
    };

    const getUserChurch = async () => {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();
        if (user) {
            const id = user.sub;
            fetch(`${BaseUrl.Path}api/churches/user-churches/${id}`, {
                method: 'GET',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
                .then(response => response.json())
                .then(data => {
                    if (data.length > 0) {
                        setLoading(false);
                        setSubmittedByUserId(id);
                        setChurchId(data[0].churchId);
                        setChurchList(data);
                    } else {
                        console.log("User has no churches");
                    }
                })
                .catch(error => console.log("Error returning churches for user:", error));
        }
    };

    const renderForm = () => {
        return (
            <Modal show={props.showCreatePastoralCareModal}>
                <Modal.Body className="custom-color-gray fancy-text text-left">
                    {form}
                    <Form id="pastoralCareForm" ref={formRef as React.RefObject<HTMLFormElement>} validated={validated}>
                        <div className="border border-dark p-2 mb-2">
                            <h5 className="text-center">Pastoral Contacts</h5>
                            <h5 className="mb-3 text-center">mchungaji wasiliana</h5>
                            <Row className="mb-2 mt-1">
                                <Form.Group as={Col} controlId="formNewBelieverContacts">
                                    <Form.Label>New Believers Contacted </Form.Label>
                                    <Form.Label> Waumini wapia waliofikiwa</Form.Label>
                                    <Form.Control required type="number" min={0} max={500} name="newBelieverContacts" value={newBelieverContacts} onChange={handleChange} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formNewBelieverFollowUp">
                                    <Form.Label>New Believer Follow Up </Form.Label>
                                    <Form.Label>Waumini wapia waliofuatiliwa</Form.Label>
                                    <Form.Control required type="number" min={0} max={500} name="newBelieverFollowUp" value={newBelieverFollowUp} onChange={handleChange} />
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} controlId="formNonBelieverContacts">
                                    <Form.Label>Non-believers Contacted </Form.Label>
                                    <Form.Label>Wasioamini waliofikiwa</Form.Label>
                                    <Form.Control required type="number" min={0} max={500} name="nonBelieverContacts" value={nonBelieverContacts} onChange={handleChange} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formNonBelieverFollowUp">
                                    <Form.Label>Non-believers Follow Up</Form.Label>
                                    <Form.Label>Wasioamini waliofuatiliwa</Form.Label>
                                    <Form.Control required type="number" min={0} max={500} name="nonBelieverFollowUp" value={nonBelieverFollowUp} onChange={handleChange} />
                                </Form.Group>
                            </Row>
                        </div>
                        <Row className="mb-4">
                            <Form.Group as={Col} controlId="formSubmitDate">
                                <Form.Label>Date ~ tarehe</Form.Label>
                                <Form.Control required type="date" name='submitDate' value={submitDate} onChange={handleChange} />
                            </Form.Group>
                        </Row>
                        <Button disabled={isDisabled} className="modal-submit-btn" onClick={handleSubmit}>
                            Submit Changes
                        </Button>
                        <Button className="modal-close-btn" onClick={handleClose}>
                            Close
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    };

    const churchDropdown = () => (
        <Form.Group controlId="formChurchSelect">
            <Form.Label>Church</Form.Label>
            <Form.Control as="select" value={churchId} onChange={handleChange} name="churchId">
                <option key='1'>Choose Church...</option>
                {churchList.map((c: any, index: number) =>
                    <option key={index} value={c.churchId}>{c.name}</option>
                )}
            </Form.Control>
        </Form.Group>
    );

    const churchLabel = () => (
        <>
            {churchList.map((c: ChurchList, index: number) =>
                <h4 key={index} className="text-center"><strong>{c.name}</strong></h4>
            )}
        </>
    );

    let form = churchList.length > 1 ? churchDropdown() : churchLabel();
    let contents = loading
        ? <p><em>Loading Form...</em></p>
        : renderForm();

    return (<>{contents}</>);
};

export default CreatePastoralCare;