import React, { useState} from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { BaseUrl } from '../../baseRoute';

interface DeleteRegionProps {
    regionId: string;
    regionName: string;
    showModal: boolean;
    updateDeleteModalParent: (value: boolean) => void;
}

const DeleteRegion: React.FC<DeleteRegionProps> = (props) => {
    const [isDisabled, setIsDisabled] = useState(false);

    const handleClose = () => {
        props.updateDeleteModalParent(false);
    };

    const handleSubmit = () => {
        setIsDisabled(true);
        fetch(`${BaseUrl.Path}api/regions/Delete-Region`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({
                regionId: props.regionId,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    props.updateDeleteModalParent(false);
                }
            })
            .catch((error) => {
                setIsDisabled(false);
                console.log("The error is:", error);
            });
    };

    return (
        <div>
            <Modal show={props.showModal} className="bg-dark fancy-text-strong text-center">
                <Modal.Body>
                    <h1 className="fancy-text-stronger mb-4">Delete Region</h1>
                    <Form id="deleteForm">
                        <h3>Are you sure you want to remove <strong>{props.regionName}</strong> region?</h3>
                        <br />
                        <h6><strong>NOTE: </strong>Any churches associated to this region will also be removed. Move churches to another region if you wish to keep them.</h6>
                        <h6 className="mb-3 mt-4">**Only Blake will be able to retrieve this region**</h6>
                        <Button className="modal-close-btn" disabled={isDisabled} onClick={handleSubmit}>Yes, Remove Region</Button>
                        <Button className="modal-submit-btn" onClick={handleClose}>
                            Close
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default DeleteRegion;
