import React from 'react';
import { Button } from 'react-bootstrap';
import '../Styles/HrefButton.css';

type MyProps = {
    label: string;
    href: string;
    classStyle?: string;
};

const HrefButton: React.FC<MyProps> = ({ label, href, classStyle }) => {
    const buttonClass = classStyle ? classStyle : "default-btn";
    return (
        <Button variant="dark" className={buttonClass} href={href}>{label}</Button>
    );
};

export default HrefButton;