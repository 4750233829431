import React, { ChangeEvent, useState, useEffect, useRef } from 'react';
import { Button, Form, Row, Col, Modal } from 'react-bootstrap';
import { BaseUrl } from '../../baseRoute';
import '../Styles/ModalStyles.css';

interface EditRegionProps {
    regionId: string;
    showModal: boolean;
    updateEditModalParent: (value: boolean) => void;
}

const EditRegion: React.FC<EditRegionProps> = (props) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [regionName, setRegionName] = useState('');
    const [loading, setLoading] = useState(true);
    const [validated, setValidated] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        if (name === 'regionName') {
            setRegionName(value);
        }
    };

    const handleClose = () => {
        props.updateEditModalParent(false);
    };

    const handleSubmit = () => {
        const regionId = props.regionId;
        setIsDisabled(true);
        setValidated(true);
        if (formRef.current && formRef.current.checkValidity() === false) {
            setIsDisabled(false);
            return;
        }
        fetch(`${BaseUrl.Path}api/regions/${regionId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({
                regionId: regionId,
                regionName: regionName,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    props.updateEditModalParent(false);
                }
            })
            .catch((error) => {
                setIsDisabled(false);
                console.log("The error is:", error);
            });
    };

    useEffect(() => {
        fetchRegionById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchRegionById = async () => {
        const id = props.regionId;
        try {
            const response = await fetch(`${BaseUrl.Path}api/regions/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                },
            });
            const data = await response.json();
            setRegionName(data.regionName);
            setLoading(false);
        } catch (error) {
            console.log("The error is:", error);
        }
    };

    const fetchForm = () => {
        return (
            <Modal show={props.showModal}>
                <Modal.Body>
                    <h4 className="modal-header-text">Modify Region</h4>
                    <Form id="editForm" ref={formRef as React.RefObject<HTMLFormElement>} validated={validated}>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formName" className="mb-3 mt-2 modal-form-label-text">
                                <Form.Label>Region Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder={regionName}
                                    name="regionName"
                                    value={regionName}
                                    onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid" className="modal-form-invalid-text"> Enter a Region Name</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Button disabled={isDisabled} className="modal-submit-btn" onClick={handleSubmit}>
                            Save
                        </Button>
                        <Button className="modal-close-btn" onClick={handleClose}>
                            Close
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    };

    let contents = loading ? <p><em>Loading Form...</em></p> : fetchForm();

    return (<div>{contents}</div>);
};

export default EditRegion;
