import React, { Component} from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import authService from './components/api-authorization/AuthorizeService';
import { BaseUrl } from './baseRoute';
import './components/Styles/custom.css';

//Custom Components
import { Admin } from './components/Admin/Admin';
import { ManageAnnouncements } from './components/Admin/ManageAnnouncements';
import { ManageChurches } from './components/Admin/ManageChurches';
import { ManageRegions } from './components/Admin/ManageRegions';
import { ManageUsers } from './components/Admin/ManageUsers';
import { Church } from './components/Church';
import { Region } from './components/Region';
import Reports from './components/ReportViews/Reports';

export default class App extends Component {
    static displayName = App.name;
    constructor(props) {
        super(props);

        this.state = {
            roles: []
        };
    }

    componentDidMount() {
        this.getRoles();
    }

    render() {
        const roles = this.state.roles;
    return (
      <Layout>
        <AuthorizeRoute exact path='/' component={Home} />
            {roles.includes("Admin") && <Route path='/admin' component={Admin} />}
            {roles.includes("Admin") && <Route path='/announcements' component={ManageAnnouncements} />}
        {roles.includes("Admin") && <Route path='/churches' component={ManageChurches} />}
        <Route path='/church/:churchId' component={Church} />
        <Route path='/region/:regionId' component={Region} />
            {roles.includes("Admin") && <Route path='/regions' component={ManageRegions} />}
            {roles.includes("Admin") && <Route path='/users' component={ManageUsers} />}
        {roles.includes("NEC") && <Route path='/reports' component={Reports} />}
        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
      </Layout>
    );
    }
    async getRoles() {
        const user = await authService.getUser();
        if (user) {
            const id = user.sub;
            fetch(`${BaseUrl.Path}api/users/Roles/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    this.setState({ roles: data })
                })
                .catch(error => { console.log("Failed to fetch user roles. The error is:", error) });
        }
    }
}
