import React, { useState, ChangeEvent } from 'react';
import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap'
import '../Styles/Table.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faMoon } from '@fortawesome/free-solid-svg-icons';
import { faMoon as faRegularMoon } from '@fortawesome/free-regular-svg-icons';

interface Column {
    title: string;
    render: (item: any) => JSX.Element;
    isFilterable?: boolean;
    field: string; // used for sorting
    contentClass?: string;
}

interface DynaTableProps {
    cols: Column[];
    data: any[];
    bordered?: boolean;
    hoverable?: boolean;
    striped?: boolean;
    isDark?: boolean;
    sizeButton?: number;
    sizeSearch?: number;
    sizeFooter?: number;
}

const DynaTable: React.FC<DynaTableProps> = ({ cols, data, bordered, hoverable, striped, isDark, sizeButton, sizeSearch, sizeFooter}) => {
    const [color, setColor] = useState<boolean>(isDark || false);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    const [sortField, setSortField] = useState<string | null>(null);

    // search logic
    const [value, setValue] = useState<string>('');
    const [tableFilter, setTableFilter] = useState<any[]>(data);
    const filterData = (x: ChangeEvent<HTMLInputElement>) => {
        setValue(x.target.value);
        if (x.target.value !== "") {
            const filterTable = data.filter(o => Object.keys(o).some(k =>
                String(o[k]).toLowerCase().includes(x.target.value.toLowerCase())
            ));
            setTableFilter([...filterTable])
        } else {
            setTableFilter(data);
        }
    }

    // sorting logic
    const handleIconClick = (column: Column) => {
        setSortField(column.field);
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    }

    const sortedData = [...tableFilter].sort((a, b) => {
        if (!sortField) return 0;
        if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
        if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
        return 0;
    });

    return (
        <div className="table-responsive custom-color-navy p-3 rounded">
            <div>
                <Table size="sm" responsive="sm" className={`table ${bordered ? 'table-bordered' : 'table-borderless'} ${hoverable && 'table-hover'} ${striped && 'table-striped'} ${color && 'table-dark'}`}>
                    <thead>
                        <tr>
                            <th colSpan={sizeSearch}>
                                <input type="text" className="form-control bg-light table-header-search" placeholder="Search" value={value} onChange={filterData} />
                            </th>
                            <th colSpan={sizeButton} className="text-right">
                                {color === true ? <FontAwesomeIcon className="icon-default-green-fade" icon={faRegularMoon} onClick={() => setColor(!color)} /> :
                                    <FontAwesomeIcon className="icon-default-green-fade" icon={faMoon} onClick={() => setColor(!color)} />}
                            </th>
                        </tr>
                        <tr className="table-column-header">
                            {cols.map((col, index) => (
                                <th key={index}>
                                    {col.title}
                                    {col.isFilterable && <FontAwesomeIcon className="mx-2" icon={faSort} onClick={() => handleIconClick(col)} />}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="bg-light">
                        {
                            sortedData.map((item, index) => {
                                return (
                                    <tr className="table-body-item" key={index}>
                                        {cols.map((col, key) => (
                                            <td className={col.contentClass} key={key}>{col.render(item)}</td>
                                        ))}
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                    <tfoot>
                        <tr className="table-footer">
                            <td colSpan={sizeFooter}>Showing {value.length > 0 ? tableFilter.length : data.length} of {data.length} records.</td>
                        </tr>
                    </tfoot>
                </Table>
            </div>
        </div>
    )
}

DynaTable.propTypes = {
    cols: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    bordered: PropTypes.bool,
    hoverable: PropTypes.bool,
    striped: PropTypes.bool,
    isDark: PropTypes.bool,
    sizeSearch: PropTypes.number,
    sizeButton: PropTypes.number,
    sizeFooter: PropTypes.number
}

DynaTable.defaultProps = {
    bordered: true,
    hoverable: false,
    striped: false,
    isDark: false,
    sizeButton: 1,
    sizeFooter: 7,
    sizeSearch: 1
}

export default DynaTable;