import React, { Component } from 'react';
import ListGroup from 'react-bootstrap/ListGroup'
import Container from 'react-bootstrap/Container'
import '../Styles/Admin.css';

export class Admin extends Component {
    render() {
        return (
            <div>
                <Container fluid className="container-color rounded p-3">
                    <h1 className="page-title">Administration Workcenter</h1>
                    <ListGroup className="p-2">
                        <ListGroup.Item className="custom-bnt-green rounded" action variant="dark" href="/Announcements">
                            Announcements
                        </ListGroup.Item>
                        <ListGroup.Item className="custom-bnt-green-alt rounded" action variant="dark" href="/Identity/Account/Register">
                            Register Account
                        </ListGroup.Item>
                        <ListGroup.Item className="custom-bnt-green rounded" action variant="dark" href="/Users">
                            Manage User Roles
                        </ListGroup.Item>
                        <ListGroup.Item className="custom-bnt-green-alt rounded" action variant="dark" href="/churches">
                            Manage Churches
                        </ListGroup.Item>
                        <ListGroup.Item className="custom-bnt-green rounded" action variant="dark" href="/Regions">
                            Manage Regions
                        </ListGroup.Item>
                        <ListGroup.Item className="custom-bnt-green-alt rounded" action variant="dark" href="/Reports">
                            Reports
                        </ListGroup.Item>
                    </ListGroup>
                </Container>
            </div>
        );
    }
}