import React, { Component } from 'react';
import { BaseUrl } from 'baseRoute';
import SpinnerComp from '../Common/SpinnerComp';
import ValueDisplayComp from '../Common/ValueDisplayComp';
import '../Styles/ValueDisplayComp.css';

export class ReportRegion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true
        };
    }

    componentDidMount() {
        this.fetchDetails();
    }

    renderRegionDetails() {
        const valueDisplayData = this.state.data.map(obj => (
            {
                name: obj.regionName,
                link: `/region/${obj.regionId}`,
                children: [{
                    label: '# of Churches',
                    value: obj.numberOfChurches
                },
                {
                    label: 'Population',
                    value: obj.regionPopulation
                },
                {
                    label: 'Men',
                    value: obj.regionMenAttendance
                },
                {
                    label: 'Male Teen',
                    value: obj.regionMaleTeenAttendance
                },
                {
                    label: 'Male Children',
                    value: obj.regionMaleChildrenAttendance
                },
                {
                    label: 'Male Youth',
                    value: obj.regionMaleYouthAttendance
                },
                {
                    label: 'Women',
                    value: obj.regionWomenAttendance
                },
                {
                    label: 'Female Teen',
                    value: obj.regionFemaleTeenAttendance
                },
                {
                    label: 'Female Youth',
                    value: obj.regionFemaleYouthAttendance
                },
                {
                    label: 'Female Children',
                    value: obj.regionFemaleChildrenAttendance
                },
                {
                    label: 'Baptisms',
                    value: obj.regionBaptisms
                },
                {
                    label: 'Offering Collected',
                    value: obj.regionOfferingCollected
                }]
            }));

        return (<ValueDisplayComp items={valueDisplayData} title="Regions" showSearch={true} />);
    }

    async fetchDetails() {
        fetch(`${BaseUrl.Path}api/regions/get-details`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({ data: data, loading: false })
            })
            .catch(error => { console.log("Failed to fetch details for region. Error:", error) });
    };

    render() {
        let contents = this.state.loading
            ? <SpinnerComp displayText='Loading Region details' />
            : this.renderRegionDetails();
        return (
            <div>
                {contents}
            </div>
        );
    }
}