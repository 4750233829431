import React, { ChangeEvent, useState, useRef } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { BaseUrl } from '../../baseRoute';
import '../Styles/ModalStyles.css';

interface Props {
    showModal: boolean;
    updateCreateModalParent: (value: boolean) => void;
}

const CreateAnnouncements: React.FC<Props> = (props) => {
    const [validated, setValidated] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const [text, setText] = useState('');
    const [expirationDateTime, setExpirationDateTime] = useState('');

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        switch (name) {
            case 'text':
                setText(value);
                break;
            case 'expirationDateTime':
                setExpirationDateTime(value);
                break;
            default: break;
        }
    };

    const handleClose = () => {
        props.updateCreateModalParent(false);
    };

    const handleSubmit = () => {
        setIsDisabled(true);
        setValidated(true);
        if (formRef.current && formRef.current.checkValidity() === false) {
            setIsDisabled(false);
            return;
        }
        fetch(`${BaseUrl.Path}api/announcements/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                text: text,
                expirationDateTime: expirationDateTime
            })
        }).then((response) => {
            if (response.ok) {
                props.updateCreateModalParent(false);
            }
        }).catch((error) => {
            setIsDisabled(false);
            console.log("The error is:", error);
        });
    };

    return (
        <Modal show={props.showModal}>
            <Modal.Body>
                <h4 className="modal-header-text">Create Announcement</h4>
                <Form id="announcement-create" ref={formRef as React.RefObject<HTMLFormElement>} validated={validated}>
                    <Form.Group className="mb-3 mt-2 modal-form-label-text" controlId="formText">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Enter a message to display to all users"
                            name="text"
                            value={text}
                            onChange={handleChange}
                            maxLength={180}
                        />
                        <Form.Control.Feedback type="invalid" className="modal-form-invalid-text">
                            Enter a message less than 180 characters
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formExpirationDateTime">
                        <Form.Label>Expiration Date (optional)</Form.Label>
                        <Form.Control type="date" name='expirationDateTime' value={expirationDateTime} onChange={handleChange} />
                    </Form.Group>
                    <Button disabled={isDisabled} className="modal-submit-btn" onClick={handleSubmit}>
                        Create
                    </Button>
                    <Button className="modal-close-btn" onClick={handleClose}>
                        Close
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateAnnouncements;