import React, { useState, useEffect } from 'react';
import { BaseUrl } from '../../baseRoute';
import '../Styles/ComplianceReport.css';
import { Container, Row, Col, Table, Button } from 'react-bootstrap';
import SpinnerComp from '../Common/SpinnerComp';
import DoughnutChartWrapper from './DoughnutChartWrapper';

interface ComplianceReportProps {
    chartTitle?: string;
}

interface PieChartData {
    labels: string[];
    data: number[];
    colors: string[];
}

interface MetricsData {
    weekNumber: string;
    shortStartDate: string;
    shortEndDate: string;
    compliantChurchCount: string;
    churchCount: string;
    regionCount: string;
    complianceRate: string;
}

const ComplianceReport: React.FC<ComplianceReportProps> = ({ chartTitle }) => {
    const [weeklyComplianceData, setWeeklyComplianceReport] = useState<MetricsData[]>([]);
    const [loadingWeeklyComplianceReport, setLoadingWeeklyComplianceReport] = useState(true);
    const [lastServiceByChurchData, setLastServiceByChurchReport] = useState<PieChartData>({
        labels: [],
        data: [],
        colors: [],
    });
    const [loadingLastServiceByChurchReport, setLoadingLastServiceByChurchReport] = useState(true);
    const [numberOfWeeks, setNumberOfWeeks] = useState(7);
    const [churchCount, setChurchCount] = useState('N/A');
    const [regionCount, setRegionCount] = useState('N/A');

    useEffect(() => {
        fetchWeeklyComplianceReport(numberOfWeeks);
        fetchLastServiceByChurchReport();
    }, [numberOfWeeks]);

    const handleLoadMoreWeeks = () => {
        setLoadingWeeklyComplianceReport(true);
        setNumberOfWeeks(numberOfWeeks + 5);
        fetchWeeklyComplianceReport(numberOfWeeks +5);
    };

    // API calls to fetch the data chart and graph
    const fetchWeeklyComplianceReport = async (weeks: number) => {        
        try {                       
            await fetch(`${BaseUrl.Path}api/report/compliance/weekly-breakdown/${weeks}`)
                .then(response => response.json())
                .then(data => {
                    setWeeklyComplianceReport(data);
                    setChurchCount(data[0].churchCount);
                    setRegionCount(data[0].regionCount);
                    setLoadingWeeklyComplianceReport(false);
                }).catch(error => console.log("Error returning WeeklyComplianceReport... ", error)); 
        } catch (error) {
            console.error('Error fetching WeeklyComplianceReport:', error);
        }
    };

    const fetchLastServiceByChurchReport = async () => {
        try {
            const response = await fetch(`${BaseUrl.Path}api/report/compliance/last-service-by-church`);
            const data = await response.json();
            setLastServiceByChurchReport(data);
            setLoadingLastServiceByChurchReport(false);
        } catch (error) {
            console.error('Error fetching LastServiceByChurchReport:', error);
        }
    };

    const getComplianceRateColor = (complianceRate: string) => {
        if (complianceRate < '20%') {
            return 'red-rate-color';
        } else if (complianceRate >= '20%' && complianceRate < '40%') {
            return 'orange-rate-color';
        } else if (complianceRate >= '40%' && complianceRate <= '60%') {
            return 'yellow-rate-color';
        } else {
            return '';
        }
    };

    const weeklyComplianceReport = loadingWeeklyComplianceReport ? (
        <SpinnerComp />) : (
            <div>
                <h2 className="fancy-text text-center mb-3">Weekly Breakdown</h2>
                <Table responsive="sm">
                    <thead className="weekly-breakdown-th">
                        <tr>
                            <th>Week</th>
                            <th className="text-center">Compliant Churches</th>
                            <th className="text-center">Compliance Rate</th>
                        </tr>
                    </thead>
                    <tbody>
                        {weeklyComplianceData.map((child, i) => (
                            <tr key={i} className="weekly-breakdown-tr">
                                <td>{child.shortStartDate} - {child.shortEndDate}</td>
                                <td className="text-center">{child.compliantChurchCount}</td>
                                <td className={`text-center ${getComplianceRateColor(child.complianceRate)}`}>
                                    {child.complianceRate}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Button variant="outline-dark custom-btn" onClick={handleLoadMoreWeeks}>Load More</Button>
            </div>        
        );

    const lastServiceByChurchReport = loadingLastServiceByChurchReport ? (
        <SpinnerComp />) : (
            <div>
                <Row>
                    <Col className="text-left fancy-text" xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
                        <h4>Agape Churches</h4>
                        <h4>Agape Regions</h4>
                    </Col>
                    <Col className="compliance-col-value">
                        <h4>{churchCount}</h4>
                        <h4>{regionCount}</h4>
                    </Col>
                    <Col className="text-left fancy-text" xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
                        <h4>Churches never compliant</h4>
                        <h4>Churches not compliant in 3 months</h4>
                    </Col>
                    <Col className="compliance-col-value">
                        <h4>{lastServiceByChurchData.data[0]}</h4>
                        <h4>{lastServiceByChurchData.data[3]}</h4>
                    </Col>
                </Row> 
                <DoughnutChartWrapper
                    data={lastServiceByChurchData}
                    showDateTimePicker={false}
                    chartTitle="Most recent service for each church" />
            </div>
    );

    return (        
        <Container fluid>
            <Row>
                <h1 className="text-center fancy-text-stronger mt-3">{chartTitle}</h1>
                <h6 className="text-center fancy-text pb-1 border-bottom">Alabama region excluded from report</h6>                
                <Col lg={8} xl={8}>
                    {lastServiceByChurchReport}
                </Col>  
                <Col lg={4} xl={4}>
                    {weeklyComplianceReport}
                </Col>           
            </Row>
        </Container>
    );
};

export default ComplianceReport;
