import React from 'react';
import DateTimePicker from '../Common/DateTimePicker';
import DateTimePickerError from '../Common/DateTimePickerError';
import { Container, Row } from 'react-bootstrap';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface DatasetItem {
    label: string;
    borderColor: string;
    backgroundColor: string;
    data: Record<string, number>;
}

interface BarChartWrapperProps {
    graphData: DatasetItem[];
    chartTitle?: string;
    toDate: string;
    fromDate: string;
    updateToDateGrandparent: (value: string) => void;
    updateFromDateGrandparent: (value: string) => void;
}

const LegendPosition = {
    Top: 'top' as const,
    Bottom: 'bottom' as const,
    Left: 'left' as const,
    Right: 'right' as const,
    Center: 'center' as const,
};

const BarChartWrapper: React.FC<BarChartWrapperProps> = ({
    graphData,
    chartTitle,
    toDate,
    fromDate,
    updateToDateGrandparent,
    updateFromDateGrandparent
}) => {
    const updateToDateParent = (value: string) => {
        updateToDateGrandparent(value);
    };

    const updateFromDateParent = (value: string) => {
        updateFromDateGrandparent(value);
    };

    const options = {
        responsive: true,
        plugins: {
            legend: { position: LegendPosition.Top },
            title: { display: false, text: 'Chart.js Bar Chart' },
        },
    };

    const dictionary = graphData.map(x => x.data);
    const hasGraphData = dictionary.some(dict =>
        Object.values(dict).some(value => typeof value === 'number' && value > 0)
    );

    const data = {
        labels: [],
        datasets: graphData,
    };

    return (
        <Container className="pb-5">
            <Row className="custom-align-right">
                <DateTimePicker
                    updateToDateParent={updateToDateParent}
                    updateFromDateParent={updateFromDateParent}
                    toDate={toDate}
                    fromDate={fromDate}
                />
            </Row>
            <Row className="bg-light">
                {hasGraphData ? (
                    <div>
                        <h2 className="text-center">{chartTitle}</h2>
                        <Bar options={options} data={data} />
                    </div>
                ) : (
                    <DateTimePickerError toDate={toDate} fromDate={fromDate} />
                )}
            </Row>
        </Container>
    );
};

export default BarChartWrapper;
