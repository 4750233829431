import React, { useEffect, useState } from 'react';
import { Button, Table, Modal } from 'react-bootstrap';
import { BaseUrl } from '../../baseRoute';

interface RegionModel {
    regionId: string;
    regionName: string;
    churches: ChurchModel[];
}

interface ChurchModel {
    churchId: string;
    name: string;
    location: string;
}

interface Props {
    regionId: string;
    showModal: boolean;
    updateChurchesByRegionModalParent: (value: boolean) => void;
}

const ChurchesByRegion: React.FC<Props> = (props) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<RegionModel | undefined>(undefined);

    const handleClose = () => {
        props.updateChurchesByRegionModalParent(false);
    };

    useEffect(() => {
        fetchChurchesByRegion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchChurchesByRegion = () => {
        fetch(`${BaseUrl.Path}api/regions/${props.regionId}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=utf-8' }
        })
            .then((response) => response.json())
            .then((data: RegionModel) => {
                setData(data);
                setLoading(false);
            })
            .catch((error) => {
                console.log("Unable to fetch churches for regions. The error is:", error);
            });
    };

    const renderTable = (region: RegionModel | undefined) => {
        return (
            <Modal show={props.showModal} onHide={handleClose}>
                <Modal.Body>
                    <h5 className="modal-header-text border-bottom border-5 border-dark rounded mb-3">{region?.regionName}</h5>
                    <Table striped borderless>
                        <thead className="modal-form-label-text text-center">
                            <tr>
                                <th>Church</th>
                                <th>Location</th>
                            </tr>
                        </thead>
                        <tbody className="modal-form-label-text">
                            {region?.churches.map((church) => (
                                <tr key={church.churchId}>
                                    <td>{church.name}</td>
                                    <td>{church.location}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer className="justify-content-center custom-color-gray">
                    <Button className="modal-close-btn" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    let contents = loading ? <p><em>Loading...</em></p> : renderTable(data);
    return <div>{contents}</div>;
};

export default ChurchesByRegion;