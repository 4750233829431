import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import '../Styles/ConfirmationModal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

interface ConfirmationModalProps {
    updateShowConfirmationParent: (value: boolean) => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = (props) => {

    const handleClose = () => {
        props.updateShowConfirmationParent(false);
    };

    return (
        <Modal show={true} onHide={handleClose} animation={true} size="lg">
            <Modal.Header className="modal-header">
                <FontAwesomeIcon icon={faCircleCheck} className="icon-success" />
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div>
                    <h1>Success!</h1>
                    <h3>Thank you for your submission.</h3> 
                    <br/>
                    <h6>Preach the word; be prepared in season and out of season; correct, rebuke and encourage—with great patience and careful instruction. <strong>-2 Timothy 4:2 (NIV)</strong></h6>
                </div>
                <Button className="confirmation-modal-close-btn" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Body>
        </Modal>
    );
};

export default ConfirmationModal;