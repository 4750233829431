import React from 'react';

type MyProps = {
    toDate: string;
    fromDate: string;
    classStyle?: string;
};

const DateTimePickerError: React.FC<MyProps> = ({ toDate, fromDate, classStyle }) => {
    return (
        <div className={`text-center custom-color-navy text-white ${classStyle}`}>
            <h2 className="mt-5">No data beginning from {toDate} ending {fromDate}</h2>
            <h3 className="mt-4 mb-5">Change date time filter to view data</h3>
        </div>
    );
};
export default DateTimePickerError;