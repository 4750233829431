import React, { Component, ReactNode } from 'react';
import EditUser from '../Admin/EditUser';
import HrefButton from '../Common/HrefButton';
import DynaTable from '../Common/DynaTable';
import '../Styles/Users.css';
import { BaseUrl } from '../../baseRoute';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';

interface User {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    churches: { [key: string]: string };
    regions: { [key: string]: string };
}

interface UsersState {
    users: User[];
    loading: boolean;
    userId: string;
    userName: string;
    email: string;
    regionId: string;
    churchId: string;
    showEditModal: boolean;
}

export class ManageUsers extends Component<{}, UsersState> {
    constructor(props: {}) {
        super(props);

        this.state = {
            users: [],
            loading: true,
            userId: '',
            userName: '',
            email: '',
            regionId: '',
            churchId: '',
            showEditModal: false
        };
    }

    // Open/Close modals and refresh table from child components
    updateEditModalParent(value: boolean) {
        this.setState({ showEditModal: value });
        this.refreshTable();
    }

    // Open/Close modals when clicking on buttons
    toggleEditModal = (userId: string): void => {
        this.setState({ showEditModal: !this.state.showEditModal, userId: userId });
    }

    componentDidMount(): void {
        this.refreshTable();
    }

    fetchUsers(users: User[]): ReactNode {
        const columns = (): any[] => {
            return [
                {
                    title: 'First',
                    render: (rowData: User): ReactNode => {
                        return <span>{rowData.firstName}</span>;
                    },
                    isFilterable: true,
                    field: 'firstName' 
                },
                {
                    title: 'Last',
                    render: (rowData: User): ReactNode => {
                        return <span>{rowData.lastName}</span>;
                    },
                    isFilterable: true,
                    field: 'lastName'
                },
                {
                    title: 'Email',
                    render: (rowData: User): ReactNode => {
                        return <span>{rowData.email}</span>;
                    },
                    isFilterable: true,
                    field: 'email'
                },
                {
                    title: 'Phone',
                    render: (rowData: User): ReactNode => {
                        return <span>{rowData.phoneNumber}</span>;
                    },
                    isFilterable: true,
                    field: 'phoneNumber'
                },
                {
                    title: 'Church',
                    render: (rowData: User): ReactNode => {
                        return <span>{Object.entries(rowData.churches).map(([key, value]) => (
                            <strong key={key}>{value}</strong>
                        ))}</span>;
                    },
                    contentClass: 'text-center'
                },
                {
                    title: 'Region',
                    render: (rowData: User): ReactNode => {
                        return <span>{Object.entries(rowData.regions).map(([key, value]) => (
                            <strong key={key}>{value}</strong>
                        ))}</span>;
                    },
                contentClass: 'text-center'
                },
                {
                    title: 'Edit',
                    render: (rowData: User): ReactNode => {
                        return <FontAwesomeIcon icon={faPencil} className="icon-edit" onClick={() => this.toggleEditModal(rowData.id)} />;
                    },
                },
            ];
        }

        return (<DynaTable cols={columns()} data={this.state.users} striped bordered={false} isDark hoverable sizeSearch={4} sizeButton={3} sizeFooter={7}/>);
    }

    async refreshTable(): Promise<void> {
        this.setState({ loading: true });
        fetch(`${BaseUrl.Path}api/users/`)
            .then((response) => response.json())
            .then((data) => {
                this.setState({ users: data, loading: false });
            })
            .catch(error => { console.log("Unable to retrieve users ", error) });
    }

    render() {
        let contents = this.state.loading ? <p><em>Loading...</em></p> : this.fetchUsers(this.state.users);

        const editModal = this.state.showEditModal ? <EditUser userId={this.state.userId} showModal={this.state.showEditModal} updateEditModalParent={this.updateEditModalParent.bind(this)} /> : null;

        return (
            <div className="p-2">
                <h2 className="page-title">User Accounts</h2>
                <HrefButton label="Register New User" href="/Identity/Account/Register"></HrefButton>
                <HrefButton label="Admin Workcenter" href="/Admin"></HrefButton>
                <div>
                    {contents}
                    {editModal}
                </div>
            </div>
        );
    }
}
