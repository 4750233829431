import React, { Component, Fragment } from 'react';
import { Nav, Row, Col, Card, Button, NavLink, NavItem} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import ServiceImage from '../assets/Service.jpg'
import AgapeImage from '../assets/Agape.png'
import PrayingImage from '../assets/Praying.jpg'
import ChurchImage from '../assets/LuandaChapel.jpg'
import AdminImage from '../assets/F4H_Logo.png'
import authService from '../components/api-authorization/AuthorizeService';
import { ApplicationPaths } from '../components/api-authorization/ApiAuthorizationConstants';
import { BaseUrl } from '../baseRoute';
import './Styles/Home.css';
import ConfirmationModal from './Modals/ConfirmationModal';
import CreateService from './Modals/CreateService';
import CreatePastoralCare from './Modals/CreatePastoralCare';
import Announcements from './Announcements/Announcements';

export class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showCreateServiceModal: false,
            showPastoralCareModal: false,
            showConfirmationModal: false,
            isAuthenticated: false,
            roles: [],
            churchList: [],
            loadingChurch: true,
            regionList: [],
            loadingRegion: false
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    updateCreateServiceModalParent(value) {
        this.setState({ showCreateServiceModal: value });
    }

    updateCreatePastoralCareModalParent(value) {
        this.setState({ showPastoralCareModal: value });
    }

    updateShowConfirmationParent(value) {
        this.setState({ showConfirmationModal: value });
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated
        });
        if (isAuthenticated) {
            if (user) {
                const id = user.sub;
                this.getRoles(id);
                this.refreshChurches(id);
                this.refreshRegions(id);
            }            
        }
    }
    async refreshChurches(id) {
            const response = await fetch(`${BaseUrl.Path}api/churches/user-churches/${id}`);
            const data = await response.json();
            this.setState({ churchList: data, loadingChurch: false });        
    }
    async refreshRegions(id) {
        const response = await fetch(`${BaseUrl.Path}api/regions/user-regions/${id}`);
        const data = await response.json();
        this.setState({ regionList: data, loadingRegion: false });
    }
    async getRoles(id) {
            fetch(`${BaseUrl.Path}api/users/Roles/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then((response) => response.json())
                .then((data) => {                    
                    this.setState({ roles: data })
                })
                .catch(error => { console.log("Error: Unable to get Roles", error) });
        
    }
    toggleShowCreateServiceModal = () => {        
        this.setState({ showCreateServiceModal: !this.state.showCreateServiceModal });
    }
    toggleShowPastoralCareModal = () => {
        console.log("toggleShowPastoralCareModal", this.state.showPastoralCareModal);
        this.setState({ showPastoralCareModal: !this.state.showPastoralCareModal });
    }

    render() {
        const { isAuthenticated} = this.state;
        if (!isAuthenticated) {
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(loginPath);
        } else {
            return this.authenticatedView();
        }
    }
    pastorOverseerView(roles) {  
        let pastorContent = roles.includes("Pastor")
            ? this.pastorView()
            : null
        let overseerContent = roles.includes("Regional Overseer")
            ? this.overseerView()
            : null
        return (
            <>
                <Row className="justify-content-center">
                    {pastorContent}
                    {overseerContent}
                    <Col lg={4} className="custom-color-navy p-2 m-1">
                        <Button className="custom-btn mb-3 mt-1" variant="outline-light" onClick={this.toggleShowCreateServiceModal}>Add New Service</Button>
                        <Card className="custom-color-green-dark text-white p-2">  
                            <Card.Img className="image-size" src={ServiceImage} />
                            <Card.ImgOverlay>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>
                    <Col lg={4} className="custom-color-green-dark p-2 m-1">
                        <Button className="custom-btn mb-3 mt-1" variant="outline-light" onClick={this.toggleShowPastoralCareModal}>Pastoral Care</Button>
                        <Card className="custom-color-navy border-black p-2">
                            <Card.Img className="image-size-alt" src={ChurchImage}/>
                            <Card.Img className="image-size-alt" src={PrayingImage}/>
                            <Card.ImgOverlay>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>
            </Row>

                </>
        );
    }

    pastorView() {
        return (
            <>
                <h5 className="nav-list-title">Select Church to view details</h5>
                <Nav className="nav-links">
                    {this.state.churchList.map((c, index) =>
                        <Nav.Link key={index} href={`/church/${c.churchId}`}><strong>{c.name}</strong></Nav.Link>
                    )}
                </Nav>
            </>
        );
    }

    overseerView() {
        return (
            <>  
                <h5 className="nav-list-title">Select region to view details</h5>
                <Nav className="nav-links">
                        {this.state.regionList.map((c, index) =>
                            <Nav.Link key={index} href={`/region/${c.regionId}`}><strong>{c.regionName}</strong></Nav.Link>
                        )}
                </Nav>
            </>
        );
    }

    adminView() {
        return (
            <Col lg={4} className="custom-color-green-fade p-2 m-1">     
                <Button href="/Admin" className="custom-btn mb-3 mt-1" variant="outline-dark">Administration</Button>
                <Card className="custom-color-navy border-black text-black p-2">
                    <Card.Img className="image-size bg-light" src={AdminImage} />
                    <Card.ImgOverlay>
                    </Card.ImgOverlay>
                </Card>
            </Col>)
    }

    necView() {
        return (
            <Col lg={4} className="custom-color-blue-gray p-2 m-1">    
                <Button href="/Reports" className="custom-btn mb-3 mt-1" variant="outline-light" size="md">Reports</Button>
                <Card className="custom-color-green-fade border-black text-black p-2">
                    <Card.Img className="image-size" src={AgapeImage} />
                    <Card.ImgOverlay>
                    </Card.ImgOverlay>
                </Card>
            </Col>)
    }
    // USE WHEN INCLUDING AUTHORIZATION
    //const token = await authService.getAccessToken();
    //const response = await fetch('weatherforecast', {
    //    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    //});

    authenticatedView() {
        const roles = this.state.roles;
        if (roles.length === 0) {
            return (
                <div className="text-center mt-5">
                    <h2>Your account has no roles assigned at this moment.</h2>
                    <h4 className="mt-3">This means you have not been assigned a Church or Region.</h4>
                    <h4 className="mt-3">Contact Peter Mugodo at peter@foundations4hope.org</h4>
                </div>
            );
        }
        let adminContent = roles.includes("Admin")
            ? this.adminView() : null
        let pastorOverseerContent = (roles.includes("Pastor") || roles.includes("Regional Overseer"))
            ? this.pastorOverseerView(roles)
            : null
        let necContent = roles.includes("NEC")
            ? this.necView()
            : null

        const createServiceModal = this.state.showCreateServiceModal ? <CreateService showCreateServiceModal={this.state.showCreateServiceModal} updateCreateServiceModalParent={this.updateCreateServiceModalParent.bind(this)} updateShowConfirmationParent={this.updateShowConfirmationParent.bind(this)} /> : null;
        const createPastoralCareModal = this.state.showPastoralCareModal ? <CreatePastoralCare showCreatePastoralCareModal={this.state.showPastoralCareModal} updateCreatePastoralCareModalParent={this.updateCreatePastoralCareModalParent.bind(this)} updateShowConfirmationParent={this.updateShowConfirmationParent.bind(this)} /> : null;
        const confirmationModal = this.state.showConfirmationModal ? <ConfirmationModal updateShowConfirmationParent={this.updateShowConfirmationParent.bind(this)} /> : null;
        return (
            <div>
                <Announcements/>
                <Row className="custom-container">
                    {pastorOverseerContent}
                    {necContent}
                    {adminContent}                    
                </Row>
                {createServiceModal}
                {createPastoralCareModal}
                {confirmationModal}
            </div>
        );

    }

    anonymousView(loginPath) {
        return (<Fragment>
            <h3>User Not Logged In</h3>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={loginPath}>Login</NavLink>
            </NavItem>
        </Fragment>);
    }
}
