import React from 'react';
import { Col, Card, Row} from 'react-bootstrap';
import '../Styles/ValueDisplayCard.css';

interface ValueDisplayData {
    name: string;
    link: string;
    children: {
        label: string,
        value: string
    }[];
}

type MyProps = {
    items: ValueDisplayData[];
};

const ValueDisplayCard: React.FC<MyProps> = ({ items }) => {
    if (items.length === 0) {
        return <h2 className="text-center my-5">Search results returned no records...</h2>;
    }

    return (
        <div className="value-display-comp-div rounded">
            {items.map((item, index) => (
                <div key={index}>
                    <Card className="value-display-card">
                        {item.link === "" ? (
                            <Card.Header className="text-center custom-color-blue-gray value-display-header-text">
                                {item.name}
                            </Card.Header>
                        ) : (
                            <Card.Header className="text-center custom-color-blue-gray">
                                <a className="value-display-header-text" href={item.link}>
                                    {item.name}
                                </a>
                            </Card.Header>
                        )}
                        <Card.Body>
                            {item.children.map((child, i) => (
                                <Row key={i} className="value-display-card-row">
                                    <Col>{child.label}</Col>
                                    <Col className="value-display">{child.value}</Col>
                                </Row>
                            ))}
                        </Card.Body>
                    </Card>
                </div>
            ))}
        </div>
    );
};

export default ValueDisplayCard;