import React, { Component } from 'react';
import { BaseUrl } from 'baseRoute';
import SpinnerComp from '../Common/SpinnerComp';
import ValueDisplayComp from '../Common/ValueDisplayComp';
import '../Styles/ValueDisplayComp.css';

export class ReportChurch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true
        };
    }

    componentDidMount() {
        this.fetchDetails();
    }

    renderDetails() {
        const valueDisplayData = this.state.data.map(obj => (
            {
                name: obj.name,
                link: `/church/${obj.churchId}`,
                children: [{
                    label: 'Address',
                    value: obj.address
                },
                {
                    label: 'City/County',
                    value: obj.city
                },
                {
                    label: 'Country',
                    value: obj.country
                },
                {
                    label: 'Population',
                    value: obj.estimateCommunityPopulation
                }]
            }));

        return (<ValueDisplayComp items={valueDisplayData} title="Churches" showSearch={true}/>);
    }

    async fetchDetails() {
        fetch(`${BaseUrl.Path}api/churches/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({ data: data, loading: false })
            })
            .catch(error => { console.log("Failed to fetch details. Error:", error) });
    };

    render() {
        let contents = this.state.loading
            ? <SpinnerComp displayText='Loading details..' />
            : this.renderDetails();
        return (
            <div>
                {contents}
            </div>
        );
    }
}