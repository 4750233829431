import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { BaseUrl } from '../../baseRoute';
import DynaTable from '../Common/DynaTable';
import HrefButton from '../Common/HrefButton';
import CreateChurch from '../Church/CreateChurch';
import EditChurch from '../Church/EditChurch';
import DeleteChurch from '../Church/DeleteChurch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrashCan } from '@fortawesome/free-solid-svg-icons';

interface Church {
    churchId: string;
    name: string;
    address: string;
    city: string;
    country: string;
    estimateCommunityPopulation: number;
}

interface ChurchesState {
    churches: Church[];
    loading: boolean;
    churchId: string;
    churchName: string;
    showCreateModal: boolean;
    showEditModal: boolean;
    showDeleteModal: boolean;
}

export class ManageChurches extends Component<{}, ChurchesState> {
    constructor(props: {}) {
        super(props);

        this.state = {
            churches: [],
            loading: true,
            churchId: '',
            churchName: '',            
            showCreateModal: false,
            showEditModal: false,
            showDeleteModal: false,
        };
    }

    // Open/Close modals and refresh table from child components
    updateCreateModalParent(value: boolean) {
        this.setState({ showCreateModal: value });
        this.refreshTable();
    }
    updateEditModalParent(value: boolean) {
        this.setState({ showEditModal: value });
        this.refreshTable();
    }
    updateDeleteModalParent(value: boolean) {
        this.setState({ showDeleteModal: value });
        this.refreshTable();
    }

    // Open/Close modals when clicking on buttons
    toggleCreateModal = (): void => {
        this.setState({ showCreateModal: !this.state.showCreateModal });
    }
    toggleEditModal = (churchId: string): void => {
        this.setState({ showEditModal: !this.state.showEditModal, churchId: churchId });
    }
    toggleRemoveModal = (churchId: string, churchName: string): void => {
        this.setState({ showDeleteModal: !this.state.showDeleteModal, churchId: churchId, churchName: churchName });
    }

    componentDidMount() {
        this.refreshTable();
    }

    fetchChurches(churches: Church[]) {
        const columns = (): any[] => {
            return [
                {
                    title: 'Name',
                    render: (rowData: any) => {
                        return <span>{rowData.name}</span>;
                    },
                    isFilterable: true,
                    field: 'name'
                },
                {
                    title: 'Address',
                    render: (rowData: any) => {
                        return <span>{rowData.address}</span>;
                    },
                    isFilterable: true,
                    field: 'address'
                },
                {
                    title: 'City',
                    render: (rowData: any) => {
                        return <span>{rowData.city}</span>;
                    },
                    isFilterable: true,
                    field: 'city',
                    contentClass: 'text-center'
                },
                {
                    title: 'Country',
                    render: (rowData: any) => {
                        return <span>{rowData.country}</span>;
                    },
                    isFilterable: true,
                    field: 'country',
                    contentClass: 'text-center'
                },
                {
                    title: 'Population',
                    render: (rowData: any) => {
                        return <span>{rowData.estimateCommunityPopulation}</span>;
                    },
                    isFilterable: true,
                    field: 'estimateCommunityPopulation',
                    contentClass: 'text-center'
                },
                {
                    title: 'Edit / Delete',
                    render: (rowData: any) => {
                        return (
                            <Row style={{ maxWidth: '120px', justifyContent: 'flex-end', marginLeft: 'auto', marginRight: '5px' }}>
                                <Col>
                                    <FontAwesomeIcon icon={faPencil} className="icon-edit" onClick={() => this.toggleEditModal(rowData.churchId)} />
                                </Col>
                                <Col>
                                    <FontAwesomeIcon icon={faTrashCan} className="icon-delete" onClick={() => this.toggleRemoveModal(rowData.churchId, rowData.name)} />
                                </Col>
                            </Row>
                        );
                    },
                    isFilterable: false,
                },
            ];
        }

        return (
            <>
                <DynaTable cols={columns()} data={churches} striped bordered={false} isDark hoverable sizeSearch={1} sizeButton={5} sizeFooter={7} />
            </>
        );
    }

    async refreshTable() {
        try {
            this.setState({ loading: true });
            const response = await fetch(`${BaseUrl.Path}api/churches/`);
            if (response.ok) {
                const data = await response.json();
                this.setState({ churches: data, loading: false });
            } else {
                console.error('Failed to fetch data from the API');
            }
        } catch (error) {
            console.error('An error occurred while fetching data:', error);
        }
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.fetchChurches(this.state.churches);

        const createModal = this.state.showCreateModal ? <CreateChurch showModal={this.state.showCreateModal} updateCreateModalParent={this.updateCreateModalParent.bind(this)} /> : null;
        const editModal = this.state.showEditModal ? <EditChurch churchId={this.state.churchId} showModal={this.state.showEditModal} updateEditModalParent={this.updateEditModalParent.bind(this)} /> : null;
        const deleteModal = this.state.showDeleteModal ? <DeleteChurch churchId={this.state.churchId} churchName={this.state.churchName} showModal={this.state.showDeleteModal} updateDeleteModalParent={this.updateDeleteModalParent.bind(this)} /> : null;

        return (
            <div className="m-2">
                <h2 className="page-title">Church Partners</h2>
                <Button className="custom-color-blue-gray m-1" variant="dark" onClick={this.toggleCreateModal}> Add Church</Button>
                <HrefButton href="/Admin" label="Admin Workcenter"></HrefButton>
                <div>
                    {contents}
                    {createModal}
                    {editModal}
                    {deleteModal}
                </div>
            </div>
        );
    }
}