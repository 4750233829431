import React from 'react';
import { Container, Table, Row } from 'react-bootstrap';
import { BaseUrl } from '../../baseRoute';
import SpinnerComp from '../Common/SpinnerComp';
import DateTimePicker from '../Common/DateTimePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck} from '@fortawesome/free-solid-svg-icons';

type StatusReportItem = {
    churchId: string;
    churchName: string;
    pastorName: string;
    hasSundayAm: boolean;
    hasSundayPm: boolean;
    hasMidweek: boolean;
    hasPrayer: boolean;
    totalAttendance: number;
};

type MyProps = {
    regionId: string,
};

type MyState = {
    days: number;
    statusReport: StatusReportItem[];
    statusReportLoading: boolean;
    toDate: string,
    fromDate:string
};

export default class PastorStatusReport extends React.Component<MyProps, MyState> {
    constructor(props: MyProps) {
        super(props);
        this.state = {
            days: 7,
            statusReport: [],
            statusReportLoading: false,
            toDate: "",
            fromDate: ""
        };
    }

    updateToDateParent(value: string) {
        this.setState({ toDate: value, statusReportLoading: true });
        this.fetchStatusReport(value, this.state.fromDate);
    }
    updateFromDateParent(value: string) {
        this.setState({ fromDate: value, statusReportLoading: true });
        this.fetchStatusReport(this.state.toDate, value);
    }

    componentDidMount() {
        this.fetchStatusReport(this.state.toDate, this.state.fromDate);
    }

    fetchReport() {
        if (this.state.statusReport.length < 1) {
            return (<h3 className="text-center mt-5">No churches assigned to region</h3>)
        }
        return (
            <div className="custom-color-navy fancy-text p-1 mt-3">
                <Row className="custom-align-center">
                    <DateTimePicker updateToDateParent={this.updateToDateParent.bind(this)} updateFromDateParent={this.updateFromDateParent.bind(this)} toDate={this.state.toDate} fromDate={this.state.fromDate} backgroundColor="custom-color-green-fade" labelColor="font-navy" />
                    <h5 className="text-center text-white">Showing results from <strong>{this.state.toDate} - {this.state.fromDate}</strong></h5>
                </Row>
            <div className="fancy-text-strong-light">
                <Table responsive="sm" striped variant="light">
                    <thead>
                        <tr>
                            <th>Church</th>
                            <th>Pastor</th>
                            <th className="text-center">Sunday AM</th>
                            <th className="text-center">Sunday PM</th>
                            <th className="text-center">Mid Week</th>
                            <th className="text-center">Prayer</th>
                            <th className="text-center">Attendance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.statusReport.map(data =>
                            <tr key={data.churchId}>
                                <td>{data.churchName}</td>
                                <td>{data.pastorName}</td>
                                <td className="text-center">{data.hasSundayAm === true ? <FontAwesomeIcon icon={faCircleCheck} className="icon-small" /> : null}</td>
                                <td className="text-center">{data.hasSundayPm === true ? <FontAwesomeIcon icon={faCircleCheck} className="icon-small" /> : null}</td>
                                <td className="text-center">{data.hasMidweek === true ? <FontAwesomeIcon icon={faCircleCheck} className="icon-small" /> : null}</td>
                                <td className="text-center">{data.hasPrayer === true ? <FontAwesomeIcon icon={faCircleCheck} className="icon-small" /> : null}</td>
                                <td className="text-center">{data.totalAttendance}</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                    <h6><FontAwesomeIcon icon={faCircleCheck} className="icon-small" /> = church has submitted service during specified time</h6>
                </div>
            </div>
        );
    }

    async fetchStatusReport(toDate: string, fromDate: string) {
        let today = new Date();
        if (toDate === "") {
            toDate = today.getFullYear() + '-01-01';
        }
        if (fromDate === "") {
            let month = (today.getMonth() + 1).toString().padStart(2, '0');
            let day = today.getDate().toString().padStart(2, '0');
            fromDate = today.getFullYear() + '-' + month + '-' + day;
        }
        const parameters = this.props.regionId + '/' + toDate + '/' + fromDate;

        const response = await fetch(`${BaseUrl.Path}api/regions/region-status-report/${parameters}`);
        const data = await response.json();
        this.setState({ statusReport: data, statusReportLoading: false, toDate: toDate, fromDate: fromDate });
    }

    render() {
        let churchStatusTable = this.state.statusReportLoading
            ? <SpinnerComp displayText='Loading status report.....Please wait....' />
            : this.fetchReport();
        return (
            <Container>
                {churchStatusTable}
            </Container>
        )
    }
}