import React, { ChangeEvent, useEffect, useState, useRef } from 'react';
import { Button, Form, Row, Col, Modal } from 'react-bootstrap';
import { BaseUrl } from '../../baseRoute';
import '../Styles/ModalStyles.css';

interface Region {
    regionId: string;
    regionName: string;
    isActive: string;
}

interface EditChurchProps {
    churchId: string;
    showModal: boolean;
    updateEditModalParent: (value: boolean) => void;
}

const EditChurch: React.FC<EditChurchProps> = (props) => {
    const [validated, setValidated] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const [regionsList, setRegionsList] = useState<Region[]>([]);
    const [regionId, setRegionId] = useState('');
    const [churchName, setChurchName] = useState('');
    const [estimateCommunityPopulation, setEstimateCommunityPopulation] = useState(0);
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [loading, setLoading] = useState(true);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        switch (name) {
            case 'regionId':
                setRegionId(value);
                break;
            case 'churchName':
                setChurchName(value);
                break;
            case 'estimateCommunityPopulation':
                setEstimateCommunityPopulation(Number(value));
                break;
            case 'address':
                setAddress(value);
                break;
            case 'city':
                setCity(value);
                break;
            case 'country':
                setCountry(value);
                break;
            default:
                break;
        }
    };

    const handleClose = () => {
        props.updateEditModalParent(false);
    };

    const handleSubmit = () => {
        const churchId = props.churchId;
        setIsDisabled(true);
        setValidated(true);
        if (formRef.current && formRef.current.checkValidity() === false) {
            setIsDisabled(false);
            return;
        }
        fetch(`${BaseUrl.Path}api/churches/${churchId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                churchId: churchId,
                name: churchName,
                estimateCommunityPopulation: estimateCommunityPopulation,
                address: address,
                city: city,
                country: country,
                regionId: regionId
            })
        }).then((response) => {
            if (response.ok) {
                props.updateEditModalParent(false);
            }
        }).catch((error) => {
            setIsDisabled(false);
            console.log("The error is:", error);
        });
    };

    useEffect(() => {
        fetchChurchRegions();
        fetchChurchById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchChurchById = () => {
        const id = props.churchId
        fetch(`${BaseUrl.Path}api/churches/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setChurchName(data.name);
                setEstimateCommunityPopulation(data.estimateCommunityPopulation);
                setAddress(data.address);
                setCity(data.city);
                setCountry(data.country);
                setRegionId(data.regionId);
                setLoading(false);
            }).catch(error => { console.log("The error is:", error) });
    };

    const fetchChurchRegions = async () => {
        const response = await fetch(`${BaseUrl.Path}api/regions/`);
        const data = await response.json();
        setRegionsList(data);
    };

    const fetchForm = () => {
        return (
            <Modal show={props.showModal}>
                <Modal.Body>
                    <h4 className="modal-header-text">Modify Church</h4>
                    <Form id="edit-church-form" ref={formRef as React.RefObject<HTMLFormElement>} validated={validated}>
                        <Row className="mb-1">
                            <Form.Group as={Col} controlId="formName">
                                <Form.Label className="modal-form-label-text">Church Name</Form.Label>
                                <Form.Control required type="text" placeholder={churchName} name="churchName" value={churchName} onChange={handleChange} />
                                <Form.Control.Feedback type="invalid" className="modal-form-invalid-text"> Enter a church name</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-1">
                            <Form.Group controlId="formAddress">
                                <Form.Label className="modal-form-label-text">Address</Form.Label>
                                <Form.Control type="text" placeholder={address} name="address" value={address} onChange={handleChange} />
                            </Form.Group>
                        </Row>
                        <Row className="mb-1">
                            <Form.Group as={Col} controlId="formCity">
                                <Form.Label className="modal-form-label-text">City</Form.Label>
                                <Form.Control type="text" placeholder={city} name="city" value={city} onChange={handleChange} />
                            </Form.Group>
                        </Row>
                        <Row className="mb-1">
                            <Form.Group as={Col} controlId="formEstimateCommunityPopulation">
                                <Form.Label className="modal-form-label-text">Estimated Population</Form.Label>
                                <Form.Control required type="number" min={0} name="estimateCommunityPopulation" value={estimateCommunityPopulation} onChange={handleChange} />
                                <Form.Control.Feedback type="invalid" className="modal-form-invalid-text"> Enter Population</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formCountry">
                                <Form.Label className="modal-form-label-text">Country</Form.Label>
                                <Form.Control required type="text" placeholder={country} name="country" value={country} onChange={handleChange} />
                            </Form.Group>
                        </Row>
                        <Form.Group className="mb-2" controlId="formRegion">
                            <Form.Label className="modal-form-label-text">Update Region</Form.Label>
                            <Form.Control as="select"
                                value={regionId}
                                required
                                onChange={handleChange}
                                name="regionId">
                                {regionsList.map(r =>
                                    <option key={r.regionId} value={r.regionId}>{r.regionName}</option>
                                )}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid" className="modal-form-invalid-text">Select a Region</Form.Control.Feedback>
                            <Form.Text className="modal-form-sub-label-text">** Optional: Only necessary if you wish to assign church to new region **</Form.Text>
                        </Form.Group>
                        <Button disabled={isDisabled} className="modal-submit-btn" onClick={handleSubmit}>
                            Save
                        </Button>
                        <Button className="modal-close-btn" onClick={handleClose}>
                            Close
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    };

    let contents = loading ? <p><em>Loading Form...</em></p> : fetchForm();
    return (<div>{contents}</div>);
};

export default EditChurch;
