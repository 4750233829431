import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ValueDisplayCard from '../Common/ValueDisplayCard';
import ValueDisplayAccordion from '../Common/ValueDisplayAccordion';
import SearchBox from '../Common/SearchBox';
import '../Styles/ValueDisplayComp.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

interface ValueDisplayData {
    name: string;
    link: string;
    children: {
        label: string,
            value: string
    } [];
}

type MyProps = {
    items: ValueDisplayData[];
    title?: string;
    showSearch?: boolean;
};

type MyState = {
    isFiltered: boolean;
    filteredData: ValueDisplayData[];
    searchText: string;
};

export default class ValueDisplayComp extends React.Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);
        this.state = {
            isFiltered: true,
            filteredData: this.props.items,
            searchText: ''
        };
    }

    updateSearchParent(value: string) {
        if (value !== "") {
            const filteredItems = this.props.items.filter((o: { [key: string]: any }) =>
              Object.keys(o).some((k: string) =>
                String(o[k as keyof typeof o]).toLowerCase().includes(value.toLowerCase())
              )
            );
            this.setState({ searchText: value, filteredData: filteredItems });
        } else {
            this.setState({ searchText: value, filteredData: this.props.items });
        }       
    }

    valueDisplayCardView() {
        return (<ValueDisplayCard items={this.state.filteredData} />)
    }

    valueDisplayAccordionView() {
        return (<ValueDisplayAccordion items={this.state.filteredData} />);
    }

    headerWithSearch() {
        return (
            <Row>
                <h4 className="fancy-text-strong text-center mt-3">{this.props.title}</h4>
                <Col>
                    <SearchBox updateSearchParent={this.updateSearchParent.bind(this)} searchText={this.state.searchText} />
                    <p className="text-right">Showing {this.state.filteredData.length} of {this.props.items.length} records.</p>
                </Col>
                <Col xs={2} xl={2}>
                    <FontAwesomeIcon className="icon-default-blue-gray" icon={faFilter} onClick={() => this.setState({ isFiltered: !this.state.isFiltered })} />
                </Col>
            </Row>
        )
    }

    headerWithoutSearch() {
        return (
            <Row>
                <Col><h4 className="fancy-text-strong">{this.props.title}</h4></Col>
                <Col xs={2} xl={2}>
                    <FontAwesomeIcon className="icon-default-blue-gray" icon={faFilter} onClick={() => this.setState({ isFiltered: !this.state.isFiltered })} />
                </Col>
            </Row>
        )
    }

    render() {
        const displayToggle = this.state.isFiltered
            ? this.valueDisplayCardView()
            : this.valueDisplayAccordionView()

        const header = this.props.showSearch
            ? this.headerWithSearch()
            : this.headerWithoutSearch()

        return (
            <div>
                {header}
                {displayToggle}
            </div>
        );
    }
}