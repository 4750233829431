import React, { Component, ReactNode } from 'react';
import { BaseUrl } from '../../baseRoute';
import DynaTable from '../Common/DynaTable';

interface ReportModel {
    church: string;
    shortDate: Date;
    shortCreatedDate: Date;
    pastorName: string;
    submittedBy: string;
    menAttendance: number;
    womenAttendance: number;
    childrenMaleAttendance: number;
    childrenFemaleAttendance: number;
    youthMaleAttendance: number;
    youthFemaleAttendance: number;
    teenMaleAttendance: number;
    teenFemaleAttendance: number;
    totalAttendance: number;
}

interface State {
    data: ReportModel[];
    loading: boolean;
}

export default class ChurchServiceReport extends Component<{},State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            data: [],
            loading: true,
        };
    }

    componentDidMount(): void {
        this.refreshTable();
    }

    async refreshTable(): Promise<void> {
        const response = await fetch(`${BaseUrl.Path}api/churches/GetChurchServiceReport`);
        const data = await response.json();
        this.setState({ data: data, loading: false });
    }

    fetchReportTable(): ReactNode {
        const columns = (): any[] => {
            return [
                {
                    title: 'Church',
                    render: (rowData: ReportModel): ReactNode => {
                        return <span>{rowData.church}</span>;
                    },
                    isFilterable: true,
                    field: 'church'
                },
                {
                    title: 'Pastor',
                    render: (rowData: ReportModel): ReactNode => {
                        return <span>{rowData.pastorName}</span>;
                    },
                    isFilterable: true,
                    field: 'pastorName'
                },
                {
                    title: 'Submitted By',
                    render: (rowData: ReportModel): ReactNode => {
                        return <span>{rowData.submittedBy}</span>;
                    },
                    isFilterable: true,
                    field: 'submittedBy'
                },
                {
                    title: 'Date',
                    render: (rowData: ReportModel): ReactNode => {
                        return <span>{rowData.shortDate}</span>;
                    },
                    isFilterable: true,
                    field: 'date'
                },
                {
                    title: 'Created',
                    render: (rowData: ReportModel): ReactNode => {
                        return <span>{rowData.shortCreatedDate}</span>;
                    },
                    isFilterable: true,
                    field: 'createdDate'
                },
                {
                    title: 'Attendance',
                    render: (rowData: ReportModel): ReactNode => {
                        return <p className="text-center">{rowData.totalAttendance}</p>;
                    },
                    isFilterable: true,
                    field: 'totalAttendance'
                },
            ];
        }

        return (
            <>
                <h6 className="text-right fancy-text mx-3">**Showing {this.state.data.length} most recent services created**</h6>
                <h6 className="text-right fancy-text mx-3">**Created Date displayed in East Africa timezone (UTC+3)**</h6>
                <DynaTable cols={columns()} data={this.state.data} striped bordered={false} hoverable sizeSearch={3} sizeButton={3} sizeFooter={6} />
            </>
        );
    }

    render(): ReactNode {
        let contents: ReactNode = this.state.loading
            ? <p>Loading...</p>
            : this.fetchReportTable();

        return (
            <div>
                <h1 className="text-center fancy-text-stronger mt-3">Church Service Report</h1>
                {contents}
            </div>
        );
    }
}