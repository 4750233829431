import React, { Component } from 'react';
import { Button, Col } from 'react-bootstrap';
import { BaseUrl } from '../../baseRoute';
import DynaTable from '../Common/DynaTable';
import HrefButton from '../Common/HrefButton'
import CreateRegion from '../Region/CreateRegion';
import EditRegion from '../Region/EditRegion';
import DeleteRegion from '../Region/DeleteRegion';
import ChurchesByRegion from '../Modals/ChurchesByRegion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrashCan } from '@fortawesome/free-solid-svg-icons';

interface Region {
    regionId: string;
    regionName: string;
}

interface RegionsState {
    churchRegions: Region[];
    regionId: string;
    regionName: string;
    loading: boolean;
    showChurchesByRegionModal: boolean;
    showEditModal: boolean;
    showCreateModal: boolean;
    showDeleteModal: boolean;
}

export class ManageRegions extends Component<{}, RegionsState> {
    constructor(props: {}) {
        super(props);

        this.state = {
            churchRegions: [],
            regionId: '',
            regionName: '',
            loading: true,
            showChurchesByRegionModal: false,
            showEditModal: false,
            showCreateModal: false,
            showDeleteModal: false,
        };
    }

    // Open/Close modals and refresh table from child components
    updateCreateModalParent(value: boolean) {
        this.setState({ showCreateModal: value });
        this.refreshTable();
    }
    updateEditModalParent(value: boolean) {
        this.setState({ showEditModal: value });
        this.refreshTable();
    }
    updateDeleteModalParent(value: boolean) {
        this.setState({ showDeleteModal: value });
        this.refreshTable();
    }
    updateChurchesByRegionModalParent(value: boolean) {
        this.setState({ showChurchesByRegionModal: value });
    }

    // Open/Close modals when clicking on buttons
    toggleCreateModal = (): void => {
        this.setState({ showCreateModal: !this.state.showCreateModal });
    }
    toggleEditModal = (regionId: string): void => {
        this.setState({ showEditModal: !this.state.showEditModal, regionId: regionId });
    }
    toggleRemoveModal = (regionId: string, regionName: string): void => {
        this.setState({ showDeleteModal: !this.state.showDeleteModal, regionId: regionId, regionName: regionName });
    }
    toggleChurchesByRegionModal = (regionId: string): void => {
        this.setState({ showChurchesByRegionModal: !this.state.showChurchesByRegionModal, regionId: regionId });
    }

    componentDidMount() {
        this.refreshTable();
    }

    fetchChurchRegions(churchRegions: Region[]) {
        const columns = (): any[] => {
            return [
                {
                    title: 'Region Name',
                    render: (rowData: any) => {
                        return <span>{rowData.regionName}</span>;
                    },
                    isFilterable: true,
                    field: 'regionName' 
                },
                {
                    title: 'Churches',
                    render: (rowData: any) => {
                        return (
                            <div className="d-flex justify-content-center">
                                <Button className="table-edit-btn" variant="dark" onClick={() => this.toggleChurchesByRegionModal(rowData.regionId)}>View Churches</Button>
                            </div>
                        );
                    },
                },
                {
                    title: 'Edit / Delete',
                    render: (rowData: any) => {
                        return (
                            <div className="d-flex">
                                <Col className="text-right" style={{ marginRight: '15px' }}>
                                    <FontAwesomeIcon icon={faPencil} className="icon-edit" onClick={() => this.toggleEditModal(rowData.regionId)} />
                                </Col>
                                <Col>
                                    <FontAwesomeIcon icon={faTrashCan} className="icon-delete" onClick={() => this.toggleRemoveModal(rowData.regionId, rowData.regionName)} />
                                </Col>
                            </div>
                        );
                    },
                },
            ];
        }
        return (<DynaTable cols={columns()} data={churchRegions} striped bordered={false} isDark hoverable sizeSearch={1} sizeButton={2} sizeFooter={3} />);
    }

    async refreshTable() {
        try {
            this.setState({ loading: true });
            const response = await fetch(`${BaseUrl.Path}api/regions/`);
            if (response.ok) {
                const data = await response.json();
                this.setState({ churchRegions: data, loading: false });
            } else {
                console.error('Failed to fetch data from the API');
            }
        } catch (error) {
            console.error('An error occurred while fetching data:', error);
        }
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.fetchChurchRegions(this.state.churchRegions);

        const createModal = this.state.showCreateModal ? <CreateRegion showModal={this.state.showCreateModal} updateCreateModalParent={this.updateCreateModalParent.bind(this)} /> : null;
        const editModal = this.state.showEditModal ? <EditRegion regionId={this.state.regionId} showModal={this.state.showEditModal} updateEditModalParent={this.updateEditModalParent.bind(this)} /> : null;
        const deleteModal = this.state.showDeleteModal ? <DeleteRegion regionId={this.state.regionId} regionName={this.state.regionName} showModal={this.state.showDeleteModal} updateDeleteModalParent={this.updateDeleteModalParent.bind(this)} /> : null;
        const churchesByRegionModal = this.state.showChurchesByRegionModal ? <ChurchesByRegion regionId={this.state.regionId} showModal={this.state.showChurchesByRegionModal} updateChurchesByRegionModalParent={this.updateChurchesByRegionModalParent.bind(this)} /> : null;

        return (
            <div className="m-1">
                <h2 className="page-title">Church Regions</h2>
                <Button className="custom-color-blue-gray m-1" variant="dark" onClick={this.toggleCreateModal}> Add Region</Button>
                <HrefButton label="Admin Workcenter" href="/Admin"></HrefButton>
                {contents}
                {createModal}
                {editModal}
                {deleteModal}
                {churchesByRegionModal}
            </div>
        );
    }
}