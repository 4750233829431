import React, { ChangeEvent, useState, useRef} from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { BaseUrl } from '../../baseRoute';
import '../Styles/ModalStyles.css';

interface CreateRegionProps {
    showModal: boolean;
    updateCreateModalParent: (value: boolean) => void;
}

const CreateRegion: React.FC<CreateRegionProps> = (props) => {
    const [validated, setValidated] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const [regionName, setRegionName] = useState('');

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        if (name === 'regionName') {
            setRegionName(value);
        }
    };

    const handleClose = () => {
        props.updateCreateModalParent(false);
    };

    const handleSubmit = () => {
        setIsDisabled(true);
        setValidated(true);
        if (formRef.current && formRef.current.checkValidity() === false) {
            setIsDisabled(false);
            return;
        }
        fetch(`${BaseUrl.Path}api/regions/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                regionName: regionName
            })
        }).then((response) => {
                if (response.ok) {
                    props.updateCreateModalParent(false);
                }
        }).catch((error) => {
            setIsDisabled(false);
            console.log("The error is:", error);
        });
    };

    return (
        <Modal show={props.showModal}>
            <Modal.Body>
                <h4 className="modal-header-text">Create Region</h4>
                <Form id="createForm" ref={formRef as React.RefObject<HTMLFormElement>} validated={validated}>
                    <Form.Group className="mb-3 mt-2 modal-form-label-text" controlId="formRegionOverseer">
                        <Form.Label>Region Name</Form.Label>
                        <Form.Control required type="text" placeholder="Enter Region Name" name="regionName" value={regionName} onChange={handleChange} />
                        <Form.Control.Feedback type="invalid" className="modal-form-invalid-text"> Enter a Region Name</Form.Control.Feedback>
                        </Form.Group>
                    <Button disabled={isDisabled} className="modal-submit-btn" onClick={handleSubmit}>
                        Create
                    </Button>
                    <Button className="modal-close-btn" onClick={handleClose}>
                        Close
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateRegion;
